


import { UseCasesContext } from "./context/useCases";

import { useUseCases } from "./hooks/useCasesHook";
import { useActions } from "./hooks/useActions";
import { RouterComponent } from "./ui/components/router";
import "./css/core.css"
import "./css/core-customized.css"
import "./css/theme-default.css"
import "./css/theme-default-c.css"
import "./css/demo.css"
import "./css/perfect-scrollbars.css"
import "./css/style.css"

function App() {
  let actions = useActions();
  let { authUseCase, paymentUseCase, mentorUseCase, recruiterUseCase, sourceUseCase, typeOfSourceUseCase, vacancyUseCase, reportUseCase, userUseCase, denyStageUseCase } = useUseCases(actions)

  return (
      <UseCasesContext.Provider value={{ authUseCase: authUseCase, paymentUseCase: paymentUseCase, mentorUseCase: mentorUseCase, recruiterUseCase: recruiterUseCase, sourceUseCase: sourceUseCase, typeOfSourceUseCase: typeOfSourceUseCase, vacancyUseCase: vacancyUseCase, reportUseCase: reportUseCase, userUseCase: userUseCase, denyStageUseCase: denyStageUseCase}}>
          <div className="App">
            <RouterComponent />
          </div>
      </UseCasesContext.Provider>
  );
}

export default App;
