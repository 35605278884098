import { RecruitersRepository } from "../../repository/api/recruiter/recruiter";
import { actionCreators } from "../../state";

export class RecruiterUseCases {
    private api: RecruitersRepository;
    private stateActions: typeof actionCreators;

    constructor(api: RecruitersRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadRecruiters = async () => {
        this.stateActions.SendRecruiterListRequest()
        let response = await this.api.ReadRecruiters()
        if (response instanceof Error) {
            this.stateActions.RecruiterListError(response.message)
            return
        } else {
            this.stateActions.RecruiterListSuccess(response)
        }
    }

}