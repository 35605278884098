import axios from "axios";
import { Source } from "../../../domain/source/source";
import { SourceInterface } from "../../../useCase/source/adapters/Interface";
import { Configuration, SourcesApi } from "../generated";

export class SourcesRepository implements SourceInterface {
    private service: SourcesApi;

    constructor() {
        this.service = new SourcesApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async ReadSources(): Promise<Source[] | Error> {
        try {
            let response = await this.service.apiSourceGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let sources: Source[] = []
                response.data.forEach(sourceData => {
                    let createdAt = new Date(sourceData.createdAt!)
                    let modifiedAt = new Date(sourceData.modifiedAt!)

                    let source = new Source(sourceData.id!, sourceData.name!, createdAt, modifiedAt)
                    sources.push(source)
                });
                return sources
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }
}