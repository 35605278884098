import { useContext, useState } from "react";
import { Payment } from "../../../../domain/payment/payment";
import { DotsVertical, Edit, Trash } from 'tabler-icons-react'
import { UseCasesContext } from "../../../../context/useCases";
import { Link, useParams } from "react-router-dom";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { ReportAutoRecruitersSum } from "../../../../repository/api/generated";

export const TableRowLast = (props: { report: ReportAutoRecruitersSum }) => {
    const { id } = useParams()
    const [showMenu, setShowMenu] = useState(false)
    let useCases = useContext(UseCasesContext)

    return (
        <div className="css_tr" style={{ fontWeight: "bold" }}>
            <div className="css_sd css_sd_nowrap">Итого</div>
            
            <div className="css_sd css_sd_nowrap">{props.report.leads_count}</div>

            <div className="css_sd css_sd_nowrap">{props.report.success_interviews_count}</div>
            <div className="css_sd css_sd_nowrap">{props.report.success_interviews_conversion}</div>

            <div className="css_sd css_sd_nowrap">{props.report.welcome_count}</div>
            <div className="css_sd css_sd_nowrap">{props.report.welcome_conversion}</div>

            <div className="css_sd css_sd_nowrap">{props.report.welcome_appearence}</div>
            <div className="css_sd css_sd_nowrap">{props.report.welcome_appearence_conversion}</div>
            
            <div className="css_sd css_sd_nowrap">{props.report.exam_passed_count}</div>
            <div className="css_sd css_sd_nowrap">{props.report.exam_passed_conversion}</div>

            <div className="css_sd css_sd_nowrap">{props.report.internship_count}</div>
            <div className="css_sd css_sd_nowrap">{props.report.internship_conversion}</div>

            <div className="css_sd css_sd_nowrap">{props.report.probation_count}</div>
            <div className="css_sd css_sd_nowrap">{props.report.probation_conversion}</div>
            <div className="css_sd css_sd_nowrap">{props.report.end_to_end_conversion}</div>

            <div className="css_sd css_sd_nowrap">{props.report.state_count}</div>
            <div className="css_sd css_sd_nowrap">{props.report.state_conversion}</div>
        </div>
    )
}
