import axios from "axios";
import { Recruiter } from "../../../domain/recruiter/recruiter";
import { RecruiterInterface } from "../../../useCase/recruiter/adapters/Interface";
import { Configuration, RecruitersApi } from "../generated";

export class RecruitersRepository implements RecruiterInterface {
    private service: RecruitersApi;

    constructor() {
        this.service = new RecruitersApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async ReadRecruiters(): Promise<Recruiter[] | Error> {
        try {
            let response = await this.service.apiRecruiterGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let recruiters: Recruiter[] = []
                response.data.forEach(recruiterData => {
                    let createdAt = new Date(recruiterData.createdAt!)
                    let modifiedAt = new Date(recruiterData.modifiedAt!)

                    let recruiter = new Recruiter(recruiterData.id!, recruiterData.name!, recruiterData.active!, createdAt, modifiedAt)
                    recruiters.push(recruiter)
                });
                return recruiters
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }
}