
import { DenyStageRepository } from "../../repository/api/denyStage/denyStage";
import { actionCreators } from "../../state";

export class DenyStageUseCases {
    private api: DenyStageRepository;
    private stateActions: typeof actionCreators;

    constructor(api: DenyStageRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadDenyStageMap = async () => {
       let response = await this.api.ReadDenyStageMap()
       return response
    }

    public ReadNotUsedDenyStageValues = async () => {
        let response = await this.api.ReadNotUsedDenyStageValues()
        return response
    }

    public CreateDenyStage = async (stage: string, value: string) => {
        let response = await this.api.CreateDenyStageMap(stage, value)
        return response
    }

    public DeleteDenyStageByName = async (name: string) => {
        let response = await this.api.DeleteDenyStageMap(name)
        return response
    }
}