import { useContext, useState } from "react";
import { Payment } from "../../../../domain/payment/payment";
import { DotsVertical, Edit, Trash } from 'tabler-icons-react'
import { UseCasesContext } from "../../../../context/useCases";
import { Link, useParams } from "react-router-dom";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { ReportSourcesItem, Sums } from "../../../../domain/report/sources";
import { MentorReportItem } from "../../../../domain/report/mentorsReport";
import { report } from "process";

export const TableRowLast = (props: { report: MentorReportItem[], showDep: boolean }) => {
    const {id} = useParams()
    const [showMenu, setShowMenu] = useState(false)
    let useCases = useContext(UseCasesContext)

    const calcSumsReportPaymentSums = () => {
        let sum = 0
        props.report.forEach(report => {
            sum += report.Payment()
        })
        return sum
    } 

    const calcEducationCount = () => {
        let count = 0
        props.report.forEach(report => {
            count += report.Education().Count()
        })
        return count
    } 

    const calcExamCount = () => {
        let count = 0
        props.report.forEach(report => {
            count += report.Exam().Count()
        })
        return count
    } 

    const calcInternshipStartCount = () => {
        let count = 0
        props.report.forEach(report => {
            count += report.InternshipStart().Count()
        })
        return count
    } 

    const calcInternshipFinishCount = () => {
        let count = 0
        props.report.forEach(report => {
            count += report.InternshipFinish().Count()
        })
        return count
    } 

    const calcProbationStartCount = () => {
        let count = 0
        props.report.forEach(report => {
            count += report.ProbationStart().Count()
        })
        return count
    } 

    const calcProbationFinishCount = () => {
        let count = 0
        props.report.forEach(report => {
            count += report.ProbationFinish().Count()
        })
        return count
    } 

    return (<tr style={{fontWeight: "bold"}}>
    <td >Итого</td>
    {props.showDep?<td></td>:<></>}
        <td>{Math.round(calcSumsReportPaymentSums())}</td>
        <td>{calcEducationCount()}</td>
        <td>{/*Math.round(props.report.Education().Price())*/}</td>
        <td>{calcExamCount()}</td>
        <td>{/*Math.round(props.report.Exam().Price())*/}</td>
        <td>{/*Math.round(props.report.Exam().Percent()*100)+"%"*/}</td>
        <td>{calcInternshipStartCount()}</td>
        <td>{/*Math.round(props.report.InternshipStart().Price())*/}</td>
        <td>{/*Math.round(props.report.InternshipStart().Percent()*100)+"%"*/}</td>
        <td>{calcInternshipFinishCount()}</td>
        <td>{/*Math.round(props.report.InternshipFinish().Price())*/}</td>
        <td>{/*Math.round(props.report.InternshipFinish().Percent()*100)+"%"*/}</td>

        <td>{calcProbationStartCount()}</td>
        <td>{/*Math.round(props.report.ProbationStart().Price())*/}</td>
        <td>{/*Math.round(props.report.ProbationStart().Percent()*100)+"%"*/}</td>
        <td>{calcProbationFinishCount()}</td>
        <td>{/*Math.round(props.report.ProbationFinish().Price())*/}</td>
        <td>{/*Math.round(props.report.ProbationFinish().Percent()*100)+"%"*/}</td>
    
</tr>)
}
