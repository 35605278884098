import { TypeOfSource } from "../../domain/typeOfSource/typeOfSource";
import { TypeOfSourceActionTypes } from "../action-types";
import { TypeOfSourceActions } from "../actions";
import produce from 'immer';


interface TypeOfSourceState {
    loading: boolean | null;
    typeOfSources: TypeOfSource[] | null,
    error: string | null,
    entityLoading: boolean,
    entityError: string | null,
}

const initialState: TypeOfSourceState = {
    loading: null,
    typeOfSources: null,
    error: null,
    entityLoading: false,
    entityError: null,
}



const reducer = produce((state: TypeOfSourceState = initialState, action: TypeOfSourceActions) => {
    switch (action.type) {
        case TypeOfSourceActionTypes.TYPE_OF_SOURCE_REQUEST_SEND:
            state.loading = true;
            return state;
        case TypeOfSourceActionTypes.TYPE_OF_SOURCE_SUCCESS:
            state.loading = false;
            state.typeOfSources = action.payload;
            return state;
        case TypeOfSourceActionTypes.TYPE_OF_SOURCE_ERROR:
            state.loading = false;
            state.error = action.payload;
            return state;
        default:
            return state;
    }
})

export default reducer;
