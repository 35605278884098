import { useContext, useState } from "react";
import { Payment } from "../../../../domain/payment/payment";
import { DotsVertical, Edit, Trash } from 'tabler-icons-react'
import { UseCasesContext } from "../../../../context/useCases";
import { Link, useParams } from "react-router-dom";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { ReportSourcesItem } from "../../../../domain/report/sources";

export const TableRow = (props: { report: ReportSourcesItem, last: boolean }) => {
    const {id} = useParams()
    const [showMenu, setShowMenu] = useState(false)
    let useCases = useContext(UseCasesContext)

    return (<tr>
        <td>{props.report.SourceName()}</td>
        <td>{Math.round(props.report.Payments().Traffic())}</td>
        <td>{Math.round(props.report.Payments().Recruiter())}</td>
        <td>{Math.round(props.report.Payments().Mentor())}</td>
        <td>{Math.round(props.report.Interview().Count())}</td>
        <td>{Math.round(props.report.Interview().Percent() * 100) + "%"}</td>
        <td>{Math.round(props.report.Interview().Price())}</td>
        <td>{Math.round(props.report.Welcome().Count())}</td>
        <td>{Math.round(props.report.Welcome().Percent() * 100) + "%"}</td>
        <td>{Math.round(props.report.Welcome().Price())}</td>
        <td>{Math.round(props.report.ComedToWelcome().Count())}</td>
        <td>{Math.round(props.report.ComedToWelcome().Percent() * 100) + "%"}</td>
        <td>{Math.round(props.report.ComedToWelcome().Price())}</td>
        <td>{Math.round(props.report.Education().Count())}</td>
        <td>{Math.round(props.report.Education().Percent()* 100) + "%"}</td>
        <td>{Math.round(props.report.Education().Price())}</td>
        <td>{Math.round(props.report.Exam().Count())}</td>
        <td>{Math.round(props.report.Exam().Percent() * 100) + "%"}</td>
        <td>{Math.round(props.report.Exam().Price())}</td>
        <td>{Math.round(props.report.Trial().Count())}</td>
        <td>{Math.round(props.report.Trial().Percent() * 100) + "%"}</td>
        <td>{Math.round(props.report.Trial().Price())}</td>
        <td>{Math.round(props.report.Stat().Count())}</td>
        <td>{Math.round(props.report.Stat().Percent() * 100) + "%"}</td>
        <td>{Math.round(props.report.Stat().Price())}</td>
    </tr>)
}
