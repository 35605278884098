import { TypeOfSourcesRepository } from "../../repository/api/typeOfSource/typeOfSource";
import { actionCreators } from "../../state";

export class TypeOfSourceUseCases {
    private api: TypeOfSourcesRepository;
    private stateActions: typeof actionCreators;

    constructor(api: TypeOfSourcesRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadTypeOfSources = async () => {
        this.stateActions.SendTypeOfSourceListRequest()
        let response = await this.api.ReadTypeOfSources()
        if (response instanceof Error) {
            this.stateActions.TypeOfSourceListError(response.message)
            return
        } else {
            this.stateActions.TypeOfSourceListSuccess(response)
        }
    }

}