import axios from "axios";
import { Vacancy } from "../../../domain/vacancy/vacancy";
import { VacancyInterface } from "../../../useCase/vacancy/adapters/Interface";
import { Configuration, VacancysApi } from "../generated";

export class VacancyRepository implements VacancyInterface {
    private service: VacancysApi;

    constructor() {
        this.service = new VacancysApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async ReadVacancysOfMonthWithDoubles(month: number): Promise<Vacancy[] | Error> {
        try {
            let response = await this.service.apiVacancyMonthGet(month, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let vacancys: Vacancy[] = []
                response.data.forEach(vacancyData => {
                    let date = new Date(vacancyData.date!)
                    let createdAt = new Date(vacancyData.createdAt!)
                    let modifiedAt = new Date(vacancyData.modifiedAt!)

                    let vacancy = new Vacancy(vacancyData.id!, vacancyData.name!, vacancyData.city!, vacancyData.type!, date, createdAt, modifiedAt)
                    vacancys.push(vacancy)
                });
                return vacancys
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async ReadVacancysOfMonthWithoutDoubles(month: number): Promise<Vacancy[] | Error> {
        try {
            let response = await this.service.apiVacancyMonthDoublesGet(month, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let vacancys: Vacancy[] = []
                response.data.forEach(vacancyData => {
                    let date = new Date(vacancyData.date!)
                    let createdAt = new Date(vacancyData.createdAt!)
                    let modifiedAt = new Date(vacancyData.modifiedAt!)

                    let vacancy = new Vacancy(vacancyData.id!, vacancyData.name!, vacancyData.city!, vacancyData.type!, date, createdAt, modifiedAt)
                    vacancys.push(vacancy)
                });
                return vacancys
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }
}