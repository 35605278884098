import { Mentor } from "../../domain/mentor/mentor";
import { MentorActionTypes } from "../action-types";
import { MentorActions } from "../actions";
import produce from 'immer';


interface MentorState {
    loading: boolean | null;
    mentors: Mentor[] | null,
    error: string | null,
    entityLoading: boolean,
    entityError: string | null,
}

const initialState: MentorState = {
    loading: null,
    mentors: null,
    error: null,
    entityLoading: false,
    entityError: null,
}



const reducer = produce((state: MentorState = initialState, action: MentorActions) => {
    switch (action.type) {
        case MentorActionTypes.MENTOR_REQUEST_SEND:
            state.loading = true;
            return state;
        case MentorActionTypes.MENTOR_SUCCESS:
            state.loading = false;
            state.mentors = action.payload;
            return state;
        case MentorActionTypes.MENTOR_ERROR:
            state.loading = false;
            state.error = action.payload;
            return state;
        default:
            return state;
    }
})

export default reducer;
