import { SourceType } from "./sources";

export class MentorsReport {
    private items: MentorReportItem[];

    constructor (items: MentorReportItem[]) {
        this.items = items
    }

    public ItemsList = (): MentorReportItem[] => {
        return this.items
    }

}


export class MentorReportItem {
    private mentorId: UniqueId;
    private mentorName: string;
    private departmentId: number;
    private departmentName: string;
    private payment: number;
    private eductation: SourceType;
    private exam: SourceType;
    private internshipStart: SourceType;
    private internshipFinish: SourceType;
    private probationStart: SourceType;
    private probationFinish: SourceType;

    constructor (mentorId: UniqueId, mentorName: string, departmentId: number, departmentName: string, payment: number, eductation: SourceType, exam: SourceType, internshipStart: SourceType, internshipFinish: SourceType, probationStart: SourceType, probationFinish: SourceType) {
        this.mentorId = mentorId
        this.mentorName = mentorName
        this.departmentId = departmentId
        this.departmentName = departmentName
        this.payment = payment
        this.eductation = eductation
        this.exam = exam
        this.internshipStart = internshipStart
        this.internshipFinish = internshipFinish
        this.probationStart = probationStart
        this.probationFinish = probationFinish

    }

    public MentorId = (): UniqueId  => {
        return this.mentorId
    }

    public MentorName = (): string => {
        return this.mentorName
    }

    public DepartmentId = (): number => {
        return this.departmentId
    }

    public DepartmentName = (): string => {
        return this.departmentName
    }

    public Payment = (): number => {
        return this.payment
    }

    public Education = (): SourceType => {
        return this.eductation
    }

    public Exam = (): SourceType => {
        return this.exam
    }

    public InternshipStart = (): SourceType => {
        return this.internshipStart
    }

    public InternshipFinish = (): SourceType => {
        return this.internshipFinish
    }

    public ProbationStart = (): SourceType => {
        return this.probationStart
    }

    public ProbationFinish = (): SourceType => {
        return this.probationFinish
    }

    public clone() {
        return new MentorReportItem(this.mentorId, this.mentorName, this.departmentId, this.departmentName, this.payment, this.eductation, this.exam, this.internshipStart, this.internshipFinish, this.probationStart, this.probationFinish);
    }

    public SumWithItem = (reportItem: MentorReportItem) : MentorReportItem => {
        let newItem = new MentorReportItem(reportItem.MentorId(), reportItem.MentorName(), reportItem.DepartmentId(), reportItem.DepartmentName(), this.payment + reportItem.payment, this.eductation.SumWithSourceType(reportItem.Education()), this.exam.SumWithSourceType(reportItem.Exam()),  this.internshipStart.SumWithSourceType(reportItem.InternshipStart()), this.internshipFinish.SumWithSourceType(reportItem.InternshipFinish()), this.probationStart.SumWithSourceType(reportItem.ProbationStart()), this.probationFinish.SumWithSourceType(reportItem.ProbationFinish()))
        return newItem
    }
}

export class MentorReportDetalization {
    private recruiterId: number;
    private recruiterName: string;
    private negotationsCount: number;
    private spentMoney: number;
    private payments: number;

    constructor (recruiterId: number, recruiterName: string, negotationsCount: number, spentMoney: number, payments: number) {
        this.recruiterId =recruiterId;
        this.recruiterName =recruiterName;
        this.negotationsCount = negotationsCount;
        this.spentMoney = spentMoney;
        this.payments = payments;
    }

    public MentorId = (): number => {
        return this.recruiterId
    }

    public MentorName = (): string => {
        return this.recruiterName
    }

    public NegtationsCount = (): number => {
        return this.negotationsCount
    }

    public SpentMoney = (): number => {
        return this.spentMoney
    }

    public Payments = (): number => {
        return this.payments
    }
}