import axios from "axios";
import { Mentor } from "../../../domain/mentor/mentor";
import { MentorInterface } from "../../../useCase/mentor/adapters/Interface";
import { AmocrmClientUserResponse, Configuration, MentorsApi } from "../generated";

export class MentorsRepository implements MentorInterface {
    private service: MentorsApi;

    constructor() {
        this.service = new MentorsApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async ReadMentors(): Promise<Mentor[] | Error> {
        try {
            let response = await this.service.apiMentorGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let mentors: Mentor[] = []
                response.data.forEach(mentorData => {
                    let createdAt = new Date(mentorData.createdAt!)
                    let modifiedAt = new Date(mentorData.modifiedAt!)

                    let mentor = new Mentor(mentorData.id!, mentorData.name!, mentorData.mentorId!, mentorData.mentorStudyId!, mentorData.mentorInternshipId!, mentorData.mentorProbationId!, createdAt, modifiedAt)
                    mentors.push(mentor)
                });
                return mentors
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async ReadUsersOfAmo(): Promise<{id: number, name: string}[] | Error> {
        try {
            let response = await this.service.apiMentorUsersGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let users: {id: number, name: string}[] = []
                response.data.forEach((user: AmocrmClientUserResponse) => {
                   
                    users.push({id: user.id!, name: user.name!})
                });

                return users!
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async ManualResumeProcess(url: string, responsibleId: number): Promise<string | Error> {
        try {
            let response = await this.service.apiMentorResumePost( {url: url, responsible_id: responsibleId},{ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return "ok"
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data
            }

            return Error(error)
        }

        return Error("something went wrong")
    }
}