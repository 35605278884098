import { Auth } from "../repository/api/auth/auth"
import { PaymentsRepository } from "../repository/api/payment/payment"
import { MentorsRepository } from "../repository/api/mentor/mentor"
import { AuthorizationUseCases } from "../useCase/authorization/authorization"
import { PaymentUseCases } from "../useCase/payment/payment"
import { MentorUseCases } from "../useCase/mentor/mentor"
import { RecruiterUseCases } from "../useCase/recruiter/recruiter"
import { RecruitersRepository } from "../repository/api/recruiter/recruiter"
import { SourcesRepository } from "../repository/api/source/source"
import { SourceUseCases } from "../useCase/source/source"

import { TypeOfSourcesRepository } from "../repository/api/typeOfSource/typeOfSource"
import { TypeOfSourceUseCases } from "../useCase/typeOfSource/typeOfSource"
import { VacancyUseCases } from "../useCase/vacancy/vacancy"
import { VacancyRepository } from "../repository/api/vacancy/vacancy"
import { ReportUseCases } from "../useCase/report/report"
import { ReportsRepository } from "../repository/api/report/report"
import { UserUseCases } from "../useCase/users/user"
import { UsersRepository } from "../repository/api/user/user"
import { DenyStageUseCases } from "../useCase/denyStage/denyStage"
import { DenyStageRepository } from "../repository/api/denyStage/denyStage"


export const useUseCases = (actions:any) => {
    const authUseCase = new AuthorizationUseCases(new Auth(), actions)
    const paymentUseCase = new PaymentUseCases(new PaymentsRepository(), actions)
    const mentorUseCase = new MentorUseCases(new MentorsRepository(), actions)
    const recruiterUseCase = new RecruiterUseCases(new RecruitersRepository(), actions)
    const sourceUseCase = new SourceUseCases(new SourcesRepository(), actions)
    const typeOfSourceUseCase = new TypeOfSourceUseCases(new TypeOfSourcesRepository(), actions)
    const vacancyUseCase = new VacancyUseCases(new VacancyRepository(), actions)
    const reportUseCase = new ReportUseCases(new ReportsRepository(), actions)
    const userUseCase = new UserUseCases(new UsersRepository(), actions)
    const denyStageUseCase = new DenyStageUseCases(new DenyStageRepository(), actions)

    return {authUseCase, paymentUseCase, mentorUseCase, recruiterUseCase, sourceUseCase, typeOfSourceUseCase, vacancyUseCase, reportUseCase, userUseCase, denyStageUseCase}
}
