import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { UseCasesContext } from "../../../../../context/useCases"
import { Menu } from "../../../../components/menu"
import { Trash } from "tabler-icons-react"

export const DenyStages = () => {
    const navigate = useNavigate();
    let { id } = useParams();
    const [denyStagesMap, setDenyStagesMap] = useState<Map<string, string[]>>(new Map())
    const [notUsedDenyStagesValues, setNotUsedDenyStagesValues] = useState<string[]>([])
    const [statuses, setStatuses] = useState<Map<string, string>>(new Map())
    const [showModal, setShowModal] = useState(false)

    const [form, setForm] = useState<{ stage: string, value: string }>({ stage: "", value: "" })

    let useCases = useContext(UseCasesContext)

    const readDenyStagesMap = async () => {
        let response = await useCases?.denyStageUseCase.ReadDenyStageMap()
        if (response instanceof Error) {

        } else {
            setDenyStagesMap(response!)
        }
    }

    const readNotUsedValues = async () => {
        let response = await useCases?.denyStageUseCase.ReadNotUsedDenyStageValues()
        if (response instanceof Error) {

        } else {
            setNotUsedDenyStagesValues(response!)
        }
    }

    const submitForm = async () => {
        if (form.value != "" && form.stage != "") {
            let response = await useCases?.denyStageUseCase.CreateDenyStage(form.stage, form.value)
            if (response instanceof Error) {

            } else {
                readDenyStagesMap()
                readNotUsedValues()
                setShowModal(false)
            }
        }
    }

    const initStatusesMap = () => {
        let statuses: Map<string, string> = new Map();
        statuses.set("welcome", "Welcome")
        statuses.set("study", "Обучение")
        statuses.set("internship", "Стажировка")
        statuses.set("probation", "Испытательный срок")
        setStatuses(statuses)
    }

    useEffect(() => {
        initStatusesMap()
        readDenyStagesMap()
        readNotUsedValues()

    }, [])


    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-12">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Список /</span> Этапы отказов</h4>
                        </div>
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Этапы отказов</h5>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-6 mb-4 mb-xl-0">
                                        {Array.from(statuses).map(([status]) => {
                                            return <div className="row">
                                                <div className="col-lg-6 mt-4 mb-4 mb-xl-0">
                                                    <span className="fw-semibold">{statuses.get(status)}</span>
                                                    <div className="demo-inline-spacing mt-3">
                                                        <div className="list-group">
                                                            {denyStagesMap.get(status)?.map((value) => {
                                                                return <div className="list-group-item list-group-item-action" style={{display: "flex", justifyContent: "space-between"}}><div>{value} </div><Trash style={{cursor: "pointer"}} size={20} color="red" onClick={(e)=>{e.preventDefault(); useCases?.denyStageUseCase.DeleteDenyStageByName(value);   readDenyStagesMap()
                                                                    readNotUsedValues()}} /></div>
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-8">
                                                    <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-start justify-content-center flex-wrap me-1">
                                                        <div className="dt-buttons py-3 mb-4">
                                                            <button className="dt-button add-new btn btn-primary mb-3 mb-md-0" onClick={(e) => { e.preventDefault(); setForm({ ...form, stage: status }); setShowModal(true) }} ><span>Добавить</span></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                    <div className="col-lg-6 mb-4 mb-xl-0">
                                        <h6 className="mb-0 mb-10">Нераспределенные статусы</h6>
                                        <div className="list-group mt-3">
                                            {notUsedDenyStagesValues.map((notUsed) => {
                                                return <div className="list-group-item list-group-item-action">{notUsed}</div>
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                showModal ? <div className="modal fade show" id="modalToggle" aria-labelledby="modalToggleLabel" style={{ display: "block" }} aria-modal="true" role="dialog">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="modalToggleLabel">Добавление статуса в "{statuses.get(form.stage)}"</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={(e) => { e.preventDefault(); setShowModal(false) }}></button>
                            </div>
                            <div className="modal-body">
                                <select value={form.value} onChange={(e) => { setForm({ ...form, value: e.target.value }) }} name="from" className="form-select mb-3">
                                    <option value={""} disabled>Выбертие статус</option>
                                    {notUsedDenyStagesValues.map((notUsed) => {
                                        return <option value={notUsed}>{notUsed}</option>
                                    })}
                                </select>
                            </div>
                            <div className="modal-footer">
                                <button onClick={(e) => { e.preventDefault(); submitForm() }} className="btn btn-primary waves-effect waves-light" data-bs-target="#modalToggle2" data-bs-toggle="modal" data-bs-dismiss="modal">
                                    Сохранить
                                </button>
                            </div>
                        </div>
                    </div>
                </div> : <></>
            }
        </div>
    )
}
