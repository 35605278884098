import { Dispatch } from "react"
import { VacancyActionTypes } from "../action-types";
import { VacancyActions } from "../actions"
import { Vacancy } from "../../domain/vacancy/vacancy";

export const SendVacancyListRequest = () => {
    return async (dispatch: Dispatch<VacancyActions>) => {
        dispatch({
            type: VacancyActionTypes.VACANCY_REQUEST_SEND,
          });
    }
}

export const VacancyListSuccess = (kvizes: Vacancy[]) => {
    return async (dispatch: Dispatch<VacancyActions>) => {
        dispatch({
            type: VacancyActionTypes.VACANCY_SUCCESS,
            payload: kvizes,
        });
    }
}

export const VacancyListError = (error:string) => {
    return async (dispatch: Dispatch<VacancyActions>) => {
        dispatch({
            type: VacancyActionTypes.VACANCY_ERROR,
            payload: error,
        });
    }
}

