import { combineReducers } from "redux";
import loginReducer from './loginReducer';
import paymentReducer from './paymentReducer';
import mentorReducer from './mentorReducer';
import recruiterReducer from './recruiterReducer';
import sourceReducer from './sourceReducer';
import typeOfSourceReducer from './typeOfSourceReducer';
import vacancyReducer from './vacancyReducer';
import userReducer from './userReducer';

const reducers = combineReducers({
    login: loginReducer,
    payment: paymentReducer,
    mentor: mentorReducer,
    recruiter: recruiterReducer,
    source: sourceReducer,
    typeOfSource: typeOfSourceReducer,
    vacancy: vacancyReducer,
    user: userReducer,
})

export default reducers;
export type RootState = ReturnType<typeof reducers>;