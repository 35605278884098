import { DownloadCloud } from 'react-feather';
import { Logout, ChartArcs3, CreditCard, Users, Settings, Download } from 'tabler-icons-react';

export const Items = [
    {
        title: "Отчеты",
        icon: <ChartArcs3 size={24} className="menu-icon"  />,
        subItems: [
            {
                title: "Наш Классик - источники",
                href: "/report/classic"
            },
            {
                title: "Наш Академия - источники",
                href: "/report/academy"
            },
            {
                title: "Наш Общий",
                href: "/report/all-sources"
            },
            {
                title: "АКЦ общий",
                href: "/report/akc-all"
            },
            {
                title: "KA общий",
                href: "/report/ka-all"
            },
            {
                title: "Рекрутеры",
                href: "/report/recruiters"
            },
            {
                title: "Наставники",
                href: "/report/mentors"
            },
            {
                title: "Ручной ввод расхода",
                href: "/report/payment/:month"
            },

            {
                title: "Автоматический по рекрутерам",
                href: "/report/recruiters-auto"
            },

            {
                title: "Отчет по кандидатам",
                href: "/report/candidates"
            },
            
        ],
    },

    {
        title: "Пользователи",
        icon: <Users size={24} className="menu-icon"  />,
        href: "/user",
    },

    {
        title: "Настройки",
        icon: <Settings size={24} className="menu-icon"  />,
        href: "/settings",
    },
    
    {
        title: "Выход",
        icon: <Logout size={24} className="menu-icon" />,
        href: "/exit",
    },

]

export const ItemsNotAdmin = [
    {
        title: "Отчеты",
        icon: <ChartArcs3 size={24} className="menu-icon"  />,
        subItems: [
            {
                title: "Наш Классик - источники",
                href: "/report/classic"
            },
            {
                title: "Наш Академия - источники",
                href: "/report/academy"
            },
            {
                title: "Наш Общий",
                href: "/report/all-sources"
            },
            {
                title: "АКЦ общий",
                href: "/report/akc-all"
            },
            {
                title: "KA общий",
                href: "/report/ka-all"
            },
            {
                title: "Рекрутеры",
                href: "/report/recruiters"
            },
            {
                title: "Наставники",
                href: "/report/mentors"
            },
            {
                title: "Ручной ввод расхода",
                href: "/report/payment/:month"
            },

            {
                title: "Автоматический по рекрутерам",
                href: "/report/recruiters-auto"
            },

            {
                title: "Отчет по кандидатам",
                href: "/report/candidates"
            },
            
        ],
    },

    
    {
        title: "Выход",
        icon: <Logout size={24} className="menu-icon" />,
        href: "/exit",
    },

]
