import { useContext, useEffect, useState } from "react"
import { UseCasesContext } from "../../../../context/useCases"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import { Link, useNavigate, useParams } from "react-router-dom"
import { TableRow } from "./tableRow"
import { Menu } from "../../../components/menu"
import { Payment } from "../../../../domain/payment/payment"

export const PaymentList = () => {
    let { month } = useParams();

    const [filter, setFilter] = useState<{ from: Date, to: Date, source: number, recruiter: number, mentor: number, city: string }>({
        from: new Date(),
        to: new Date(),
        source: 0,
        recruiter: 0,
        mentor: 0,
        city: ""
    })

    const navigate = useNavigate();

    const [monthsList, setMonthsList] = useState<{ name: string, date: string }[]>([])
    const [cityList, setCityList] = useState<{title: string, value: string}[]>([])

    useEffect(() => {
        let date = new Date()
        if (!month) {
            return
        }
        let monthInt = parseInt(month!)
        date.setMonth(monthInt - 1)
        while (date.getDate() !== 1) {
            date.setDate(date.getDate() - 1)
        }

        date.setHours(0)
        date.setMinutes(0)

        let firstDay = new Date(date.getTime());

        date.setMonth(date.getMonth() + 1)

        let lastDay = date;
        lastDay.setDate(lastDay.getDate() - 1)
        lastDay.setHours(23)
        lastDay.setMinutes(59)

        setFilter({
            ...filter,
            from: firstDay,
            to: lastDay,
            source: 0,
            recruiter: 0,
            mentor: 0
        })
    }, [])
    let useCases = useContext(UseCasesContext)

    const payments = useTypedSelector(({ payment }) => {
        return payment
    })

    const mentor = useTypedSelector(({ mentor }) => {
        return mentor
    })

    const recruiter = useTypedSelector(({ recruiter }) => {
        return recruiter
    })

    const source = useTypedSelector(({ source }) => {
        return source
    })

    const typeOfSource = useTypedSelector(({ typeOfSource }) => {
        return typeOfSource
    })

    const vacancies = useTypedSelector(({ vacancy }) => {
        return vacancy
    })

    useEffect(() => {
        if (filter.from) {
            useCases?.paymentUseCase.ReadPayments(filter.from, filter.to, filter.mentor, filter.recruiter, filter.source)
        }
    }, [filter])

    useEffect(()=>{
        let cities: {title: string, value: string}[] = []
        if (vacancies?.vacancies) {
            vacancies.vacancies.forEach((vacancy)=>{
                if (cities.filter((city)=> city.title === vacancy.City()).length === 0) {
                    cities.push({title: vacancy.City(), value: vacancy.City()})
                }
            })
        }
        setCityList(cities)
    },[vacancies?.vacancies])

    const formatDate = (day: Date) => {
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return yyyy + "-" + mm + '-' + dd;
    }

    useEffect(()=>{
        useCases?.vacancyUseCase.ReadVacancysWithDouble(filter.from.getMonth()+1)
    },[filter.from, filter.to])

    useEffect(() => {
        if (mentor?.mentors === null) {
            useCases?.mentorUseCase.ReadMentors()
        }

        if (recruiter?.recruiters === null) {
            useCases?.recruiterUseCase.ReadRecruiters()
        }

        if (source?.sources === null) {
            useCases?.sourceUseCase.ReadSources()
        }

        if (typeOfSource?.typeOfSources === null) {
            useCases?.typeOfSourceUseCase.ReadTypeOfSources()
        }
    }, [])

    const selectChange = (name: string, value: number) => {
        if (name === "mentor") {
            setFilter({
                ...filter,
                source: 0,
                [name]: value
            })
        } else if (name === "source") {
            setFilter({
                ...filter,
                mentor: 0,
                [name]: value
            })
        } else {
            setFilter({
                ...filter,
                [name]: value
            })
        }
    }

    const months = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"]

    const getDates = () => {
        let monthsList: { name: string, date: string }[] = []
        let date = new Date()
        date.setDate(date.getDate() - 180);
        while (date.getDate() !== 1) {
            date.setDate(date.getDate() - 1)
        }
        for (let i = 0; i < 7; i++) {
            monthsList.push({
                name: months[date.getMonth()] + " " + date.getFullYear(),
                date: formatDate(date)
            })
            date.setDate(date.getDate() + 30);
            if (date.getDate() < 15) {
                while (date.getDate() !== 1) {
                    date.setDate(date.getDate() - 1)
                }
            } else {
                while (date.getDate() !== 1) {
                    date.setDate(date.getDate() + 1)
                }
            }
        }
        setMonthsList([...monthsList])
    }

    const monthChange = (value: string) => {
        let lastDay = new Date(value)
        lastDay.setMonth(lastDay.getMonth() + 1)
        lastDay.setDate(lastDay.getDate() - 1)
        lastDay.setHours(23)
        lastDay.setMinutes(59)
        setFilter({
            ...filter,
            from: new Date(value),
            to: lastDay,
        })
        navigate("/report/payment/"+(lastDay.getMonth()+1), { replace: true })
    }

    const calcPayments = () => {
        let paymentSum = 0
        payments?.payments?.forEach((payment) => {
            paymentSum += payment.Amount()
        })
        return paymentSum
    }

    useEffect(() => {
        getDates()
    }, [])

    const checkCity = (payment: Payment): boolean => {
        if (filter.city === "") {
            return true
        }
        if (!vacancies?.vacancies) {
            return false
        }
        for (let i = 0; i < vacancies?.vacancies!.length; i++) { 
            if (vacancies.vacancies[i].Id() === payment.VacancyId() && vacancies.vacancies[i].City() === filter.city) {
                return true
            }
        }
        return false
    }

    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-4">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Список платежей /</span> Платежи</h4>
                        </div>
                        <div className="col-sm-12 col-md-8">
                            <div className="dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-md-end justify-content-center flex-wrap me-1">
                                <div className="dt-buttons py-3 mb-4">
                                    <Link to={"/report/payment/add/"} className="dt-button add-new btn btn-primary mb-3 mb-md-0" ><span>Добавить платеж</span></Link>
                                </div>
                            </div>
                        </div>

                        <div className="card mb-4">
                            <div className="card-body">
                                <div className="row">
                                    <h6 className="fw-bold">Фильтры</h6>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12 col-sm-6 col-lg-4 justify-content-end">
                                        <label className="form-label">Месяц:</label>
                                        <select value={formatDate(filter.from)} onChange={(e) => monthChange(e.target.value)} name="from" className="form-select mb-3">
                                            {monthsList.map((monthItem) => {
                                                return <option value={monthItem.date}>{monthItem.name}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-6 col-lg-4 justify-content-end" key="source">
                                        <label className="form-label">Источник:</label>
                                        <select value={filter.source} onChange={(e) => selectChange(e.target.name, parseInt(e.target.value))} name="source" className="form-select mb-3">
                                            <option selected value="0">Не выбран</option>
                                            {source?.sources && source?.sources.map((source) => {
                                                return <option value={source.Id()}>{source.Name()}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 justify-content-end" key="mentor">
                                        <label className="form-label">Наставник:</label>
                                        <select value={filter.mentor} onChange={(e) => selectChange(e.target.name, parseInt(e.target.value))} name="mentor" className="form-select mb-3">
                                            <option selected value="0">Не выбран</option>
                                            {mentor?.mentors && mentor?.mentors.map((source) => {
                                                return <option value={source.Id()}>{source.Name()}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 justify-content-end" key="recruiter">
                                        <label className="form-label">Рекрутер:</label>
                                        <select value={filter.recruiter} onChange={(e) => selectChange(e.target.name, parseInt(e.target.value))} name="recruiter" className="form-select mb-3">
                                            <option selected value="0">Не выбран</option>
                                            {recruiter?.recruiters && recruiter?.recruiters.map((source) => {
                                                return <option value={source.Id()}>{source.Name()}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 justify-content-end" key="recruiter">
                                        <label className="form-label">Город:</label>
                                        <select value={filter.city} onChange={(e) =>  setFilter({...filter,city: e.target.value })} name="city" className="form-select mb-3">
                                            <option selected value="">Не выбран</option>
                                            {cityList?.map((city) => {
                                                if (city.title != "") {
                                                    return <option value={city.value}>{city.title}</option>
                                                }
                                            })}
                                        </select>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="card">
                            <div className="table-responsive text-nowrap_">
                                <table className="table mt-1">
                                    <thead>
                                        <tr>
                                            <th>Период</th>
                                            <th>Тип</th>
                                            <th>Источник</th>
                                            <th>Назначение</th>
                                            <th>Получатель</th>
                                            <th>Вакансия</th>
                                            <th>Город</th>
                                            <th>Тип</th>
                                            <th>Дата вакансии</th>
                                            <th>Сумма</th>
                                            <th>Действия</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table table-hover">
                                        {
                                            payments?.payments && payments.payments.map((payment, index) => {
                                                if (checkCity(payment)) {
                                                    return <TableRow payment={payment} last={payments?.payments!.length - 1 == index ? true : false} />
                                                }
                                            })
                                        }
                                        <tr style={{ fontWeight: "bold" }}>
                                            <td >Итого</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>{calcPayments()}</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

}