import { Dispatch } from "react"
import { SourceActionTypes } from "../action-types";
import { SourceActions } from "../actions"
import { Source } from "../../domain/source/source";

export const SendSourceListRequest = () => {
    return async (dispatch: Dispatch<SourceActions>) => {
        dispatch({
            type: SourceActionTypes.SOURCE_REQUEST_SEND,
          });
    }
}

export const SourceListSuccess = (kvizes: Source[]) => {
    return async (dispatch: Dispatch<SourceActions>) => {
        dispatch({
            type: SourceActionTypes.SOURCE_SUCCESS,
            payload: kvizes,
        });
    }
}

export const SourceListError = (error:string) => {
    return async (dispatch: Dispatch<SourceActions>) => {
        dispatch({
            type: SourceActionTypes.SOURCE_ERROR,
            payload: error,
        });
    }
}

