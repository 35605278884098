import { Dispatch } from "react"
import { TypeOfSourceActionTypes } from "../action-types";
import { TypeOfSourceActions } from "../actions"
import { TypeOfSource } from "../../domain/typeOfSource/typeOfSource";

export const SendTypeOfSourceListRequest = () => {
    return async (dispatch: Dispatch<TypeOfSourceActions>) => {
        dispatch({
            type: TypeOfSourceActionTypes.TYPE_OF_SOURCE_REQUEST_SEND,
          });
    }
}

export const TypeOfSourceListSuccess = (kvizes: TypeOfSource[]) => {
    return async (dispatch: Dispatch<TypeOfSourceActions>) => {
        dispatch({
            type: TypeOfSourceActionTypes.TYPE_OF_SOURCE_SUCCESS,
            payload: kvizes,
        });
    }
}

export const TypeOfSourceListError = (error:string) => {
    return async (dispatch: Dispatch<TypeOfSourceActions>) => {
        dispatch({
            type: TypeOfSourceActionTypes.TYPE_OF_SOURCE_ERROR,
            payload: error,
        });
    }
}

