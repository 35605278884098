import { Source } from "../../domain/source/source";
import { SourceActionTypes } from "../action-types";
import { SourceActions } from "../actions";
import produce from 'immer';


interface SourceState {
    loading: boolean | null;
    sources: Source[] | null,
    error: string | null,
    entityLoading: boolean,
    entityError: string | null,
}

const initialState: SourceState = {
    loading: null,
    sources: null,
    error: null,
    entityLoading: false,
    entityError: null,
}



const reducer = produce((state: SourceState = initialState, action: SourceActions) => {
    switch (action.type) {
        case SourceActionTypes.SOURCE_REQUEST_SEND:
            state.loading = true;
            return state;
        case SourceActionTypes.SOURCE_SUCCESS:
            state.loading = false;
            state.sources = action.payload;
            return state;
        case SourceActionTypes.SOURCE_ERROR:
            state.loading = false;
            state.error = action.payload;
            return state;
        default:
            return state;
    }
})

export default reducer;
