import { Recruiter } from "../../domain/recruiter/recruiter";
import { RecruiterActionTypes } from "../action-types";
import { RecruiterActions } from "../actions";
import produce from 'immer';


interface RecruiterState {
    loading: boolean | null;
    recruiters: Recruiter[] | null,
    error: string | null,
    entityLoading: boolean,
    entityError: string | null,
}

const initialState: RecruiterState = {
    loading: null,
    recruiters: null,
    error: null,
    entityLoading: false,
    entityError: null,
}



const reducer = produce((state: RecruiterState = initialState, action: RecruiterActions) => {
    switch (action.type) {
        case RecruiterActionTypes.RECRUITER_REQUEST_SEND:
            state.loading = true;
            return state;
        case RecruiterActionTypes.RECRUITER_SUCCESS:
            state.loading = false;
            state.recruiters = action.payload;
            return state;
        case RecruiterActionTypes.RECRUITER_ERROR:
            state.loading = false;
            state.error = action.payload;
            return state;
        default:
            return state;
    }
})

export default reducer;
