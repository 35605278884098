export class Mentor {
    private id: UniqueId;
    private name: string;
    private mentorId: number;
    private mentorStudyId: number;
    private mentorInternshipId: number;
    private mentorProbationId: number;
    private createdAt: Date;
    private modifiedAt: Date;

    constructor(id: UniqueId, name: string, mentorId: number, mentorStudyId: number, mentorInternshipId: number, mentorProbationId: number, createdAt: Date, modifiedAt: Date) {
        this.id = id;
        this.name = name;
        this.mentorId = mentorId;
        this.mentorStudyId = mentorStudyId;
        this.mentorInternshipId = mentorInternshipId;
        this.mentorProbationId = mentorProbationId;
        this.createdAt = createdAt;
        this.modifiedAt = modifiedAt;
    }

    public Id = (): UniqueId => {
        return this.id
    }

    public Name = (): string => {
        return this.name
    }

    public MentorId = (): number => {
        return this.mentorId
    }

    public MentorStudyId = (): number => {
        return this.mentorStudyId
    }

    public MentorInternshipId = (): number => {
        return this.mentorInternshipId
    }

    public MentorProbationId = (): number => {
        return this.mentorProbationId
    }

    public CreatedAt = (): Date => {
        return this.createdAt
    }

    public ModifiedAt = (): Date => {
        return this.modifiedAt
    }

}