export class Recruiter {
    private id: number;
    private name: string;
    private active: boolean;
    private createdAt: Date;
    private modifiedAt: Date;

    constructor(id: number, name: string, active: boolean, createdAt: Date, modifiedAt: Date) {
        this.id = id;
        this.name = name;
        this.active = active;
        this.createdAt = createdAt;
        this.modifiedAt = modifiedAt;
    }

    public Id = (): number => {
        return this.id
    }

    public Name = (): string => {
        return this.name
    }

    public Active = (): boolean => {
        return this.active
    }

    public CreatedAt = (): Date => {
        return this.createdAt
    }

    public ModifiedAt = (): Date => {
        return this.modifiedAt
    }

}