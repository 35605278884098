import { MentorsReport } from "../../domain/report/mentorsReport";
import { RecruitersReport } from "../../domain/report/recruitersReport";
import { Report, ReportSourcesItem, Sums } from "../../domain/report/sources";
import { ReportAutoRecruitersReport, ReportCandidatesReport, ReportSelectionSourceReport } from "../../repository/api/generated";
import { ReportsRepository } from "../../repository/api/report/report";
import { actionCreators } from "../../state";

export class ReportUseCases {
    private api: ReportsRepository;
    private stateActions: typeof actionCreators;

    constructor(api: ReportsRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadClassicSourcesReport = async (from: Date, to: Date): Promise<{items: ReportSourcesItem[], sums: Sums} | Error> => {
        let response = await this.api.ReportSourcesClassic(from, to)
        return response
    }

    public ReadAcademySourcesReport = async (from: Date, to: Date): Promise<{items: ReportSourcesItem[], sums: Sums} | Error> => {
        let response = await this.api.ReportSourcesAcademy(from, to)
        return response
    }

    public ReadAllSourcesReport = async (from: Date, to: Date): Promise<{items: ReportSourcesItem[], sums: Sums} | Error> => {
        let response = await this.api.ReportSourcesAll(from, to)
        return response
    }

    public ReadAkcAllReport = async (from: Date, to: Date): Promise<{items: ReportSourcesItem[], sums: Sums} | Error> => {
        let response = await this.api.ReportAkcAll(from, to)
        return response
    }

    public ReadKaAllReport = async (from: Date, to: Date): Promise<{items: ReportSourcesItem[], comment: string, sums: Sums} | Error> => {
        let response = await this.api.ReportKaAll(from, to)
        return response
    }

    public ReadRecruitersReport = async (from: Date, to: Date): Promise<RecruitersReport | Error> => {
        let response = await this.api.RecruitersReport(from, to)
        return response
    }

    public ReadMentorsReport = async (from: Date, to: Date): Promise<MentorsReport | Error> => {
        let response = await this.api.MentorsReport(from, to)
        return response
    }

    public ReadReportList = async (): Promise<Report[] | Error> => {
        let response = await this.api.ReportList()
        return response
    }

    public ReadRecruitersAutoReport = async (from: Date, to: Date): Promise<ReportAutoRecruitersReport | Error> => {
        let response = await this.api.RecruitersAutoReport(from ,to)
        return response
    }

    public RecruitersCandidatesReport = async (from: Date, to: Date, type: string): Promise<ReportCandidatesReport | Error> => {
        let response = await this.api.RecruitersCandidatesReport(from ,to, type)
        return response
    }
}