import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Login } from "../../pages/auth/login/login";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useContext, useEffect } from "react";
import { UseCasesContext } from "../../../context/useCases";
import { PaymentList } from "../../pages/payments/list";
import { PaymentAdd } from "../../pages/payments/add";
import { ClassicReport } from "../../pages/reports/classic-report";
import { UserList } from "../../pages/users/list";
import { UserAdd } from "../../pages/users/add";
import { AcademyReport } from "../../pages/reports/academy-report";
import { SourcesAllReport } from "../../pages/reports/all-report";
import { AkcReport } from "../../pages/reports/akc-report";
import { KaReport } from "../../pages/reports/ka-report";
import { RecruitersReport } from "../../pages/reports/recruiters";
import { ReportMentors } from "../../pages/reports/mentors";
import { Settings } from "../../pages/settings";
import { ManualHeadHunterResumeAdd } from "../../pages/manual_head_hunter_resume_add/add";
import { RecruitersAutoReport } from "../../pages/reports/recruiters-auto-report";
import { CandidatesReport } from "../../pages/reports/candidates-report";
import { DenyStages } from "../../pages/reports/candidates-report/deny-stages";

export const RouterComponent = () => {
  let useCases = useContext(UseCasesContext)

  const login = useTypedSelector(({ login }) => {
    return login
  })

  useEffect(() => {
    useCases?.authUseCase.CheckAuthorization()
    setInterval(()=>{
      useCases?.authUseCase.CheckAuthorization()
    },10000)
  }, [])
 
  
  return (<Router basename="/">
    <Routes>
      {login?.authorized ? useCases?.authUseCase.IsHunterUser()? <>
        <Route path="*" element={<ManualHeadHunterResumeAdd />} />
      </>: <>
        <Route path="/report/candidates/deny-stages" element={<DenyStages />} />
        <Route path="/report/candidates" element={<CandidatesReport />} />

        <Route path="/user/edit/:id" element={<UserAdd />} />
        <Route path="/user/add" element={<UserAdd />} />
        <Route path="/user" element={<UserList />} />
        
        <Route path="/report/recruiters-auto" element={<RecruitersAutoReport />} />

        <Route path="/report/academy" element={<AcademyReport />} />
        <Route path="/report/all-sources" element={<SourcesAllReport />} />
        <Route path="/report/akc-all" element={<AkcReport />} />
        <Route path="/report/ka-all" element={<KaReport />} />
        <Route path="/report/mentors" element={<ReportMentors />} />
        
        <Route path="/report/recruiters" element={<RecruitersReport />} />

        <Route path="/report/payment/edit/:id" element={<PaymentAdd />} />
        <Route path="/report/payment/add" element={<PaymentAdd />} />

        <Route path="/report/payment/:month" element={<PaymentList />} />
        <Route path="/settings" element={<Settings />} />

        <Route path="*" element={<ClassicReport />} />
      </> : <>
        <Route path="*" element={<Login />} />
      </>}
    </Routes>
  </Router>
  )
}