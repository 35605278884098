import { Payment } from "../../domain/payment/payment";
import { PaymentActionTypes } from "../action-types";
import { PaymentActions } from "../actions";
import produce from 'immer';


interface PaymentState {
    loading: boolean | null;
    payments: Payment[] | null,
    error: string | null,
    entityLoading: boolean,
    entityError: string | null,
}

const initialState: PaymentState = {
    loading: null,
    payments: null,
    error: null,
    entityLoading: false,
    entityError: null,
}



const reducer = produce((state: PaymentState = initialState, action: PaymentActions) => {
    switch (action.type) {
        case PaymentActionTypes.PAYMENT_REQUEST_SEND:
            state.loading = true;
            return state;
        case PaymentActionTypes.PAYMENT_SUCCESS:
            state.loading = false;
            state.payments = action.payload;
            return state;
        case PaymentActionTypes.PAYMENT_ERROR:
            state.loading = false;
            state.error = action.payload;
            return state;
        case PaymentActionTypes.PAYMENT_CREATE_REQUEST:
            state.entityLoading = true
            state.entityError = ""
            return state;
        case PaymentActionTypes.PAYMENT_CREATE_SUCCESS:
            state.entityLoading = false
            state.payments!.push(action.payload)
            state.entityError = ""
            return state;
        case PaymentActionTypes.PAYMENT_CREATE_ERROR:
            state.entityLoading = false
            state.entityError = action.payload;
            return state;
        case PaymentActionTypes.PAYMENT_DELETE_SUCCESS:
            state.payments = state.payments!.filter((payments) => payments.Id() !== action.payload)
            return state;
        default:
            return state;
    }
})

export default reducer;
