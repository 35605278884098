import axios from "axios";
import { TypeOfSource } from "../../../domain/typeOfSource/typeOfSource";
import { TypeOfSourceInterface } from "../../../useCase/typeOfSource/adapters/Interface";
import { Configuration, TypeOfSourcesApi } from "../generated";

export class TypeOfSourcesRepository implements TypeOfSourceInterface {
    private service: TypeOfSourcesApi;

    constructor() {
        this.service = new TypeOfSourcesApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async ReadTypeOfSources(): Promise<TypeOfSource[] | Error> {
        try {
            let response = await this.service.apiTypeOfSourceGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let typeOfSources: TypeOfSource[] = []
                response.data.forEach(typeOfSourceData => {
                    let createdAt = new Date(typeOfSourceData.createdAt!)
                    let modifiedAt = new Date(typeOfSourceData.modifiedAt!)

                    let typeOfSource = new TypeOfSource(typeOfSourceData.id!, typeOfSourceData.name!, createdAt, modifiedAt)
                    typeOfSources.push(typeOfSource)
                });
                return typeOfSources
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }
}