import { Dispatch } from "react"
import { MentorActionTypes } from "../action-types";
import { MentorActions } from "../actions"
import { Mentor } from "../../domain/mentor/mentor";

export const SendMentorListRequest = () => {
    return async (dispatch: Dispatch<MentorActions>) => {
        dispatch({
            type: MentorActionTypes.MENTOR_REQUEST_SEND,
          });
    }
}

export const MentorListSuccess = (kvizes: Mentor[]) => {
    return async (dispatch: Dispatch<MentorActions>) => {
        dispatch({
            type: MentorActionTypes.MENTOR_SUCCESS,
            payload: kvizes,
        });
    }
}

export const MentorListError = (error:string) => {
    return async (dispatch: Dispatch<MentorActions>) => {
        dispatch({
            type: MentorActionTypes.MENTOR_ERROR,
            payload: error,
        });
    }
}

