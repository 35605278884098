import { useContext, useRef, useState } from "react";
import { Payment } from "../../../../domain/payment/payment";
import { DotsVertical, Edit, Trash } from 'tabler-icons-react'
import { UseCasesContext } from "../../../../context/useCases";
import { Link, useParams } from "react-router-dom";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { ReportSourcesItem } from "../../../../domain/report/sources";

export const TableRow = (props: { report: ReportSourcesItem, last: boolean }) => {
    const { id } = useParams()
    const [showMenu, setShowMenu] = useState(false)
    let useCases = useContext(UseCasesContext)
    const rowRef = useRef<any>(null);

    return (<div className="css_tr" ref={rowRef}>
            <div className="css_sd css_sd_nowrap">{props.report.SourceName()}</div>
            <div className="css_sd css_sd_nowrap">{Math.round(props.report.Payments().Traffic())}</div>
            <div className="css_sd css_sd_nowrap">{Math.round(props.report.Payments().Mentor())}</div>
            <div className="css_sd css_sd_nowrap">{Math.round(props.report.Payments().HrManager())}</div>
            <div className="css_sd css_sd_nowrap">{Math.round(props.report.Welcome().Count())}</div>
            <div className="css_sd css_sd_nowrap">{Math.round(props.report.ComedToWelcome().Count())}</div>
            <div className="css_sd css_sd_nowrap">{Math.round(props.report.ComedToWelcome().Percent() * 100) + "%"}</div>
            <div className="css_sd css_sd_nowrap">{Math.round(props.report.ComedToWelcome().Price())}</div>
            <div className="css_sd css_sd_nowrap">{Math.round(props.report.Education().Count())}</div>
            <div className="css_sd css_sd_nowrap">{Math.round(props.report.Education().Percent() * 100) + "%"}</div>
            <div className="css_sd css_sd_nowrap">{Math.round(props.report.Education().Price())}</div>
            <div className="css_sd css_sd_nowrap">{Math.round(props.report.Exam().Count())}</div>
            <div className="css_sd css_sd_nowrap">{Math.round(props.report.Exam().Percent() * 100) + "%"}</div>
            <div className="css_sd css_sd_nowrap">{Math.round(props.report.Exam().Price())}</div>
            <div className="css_sd css_sd_nowrap">{Math.round(props.report.Trial().Count())}</div>
            <div className="css_sd css_sd_nowrap">{Math.round(props.report.Trial().Percent() * 100) + "%"}</div>
            <div className="css_sd css_sd_nowrap">{Math.round(props.report.Trial().Price())}</div>
            <div className="css_sd css_sd_nowrap">{Math.round(props.report.Stat().Count())}</div>
            <div className="css_sd css_sd_nowrap">{Math.round(props.report.Stat().Percent() * 100) + "%"}</div>
            <div className="css_sd css_sd_nowrap">{Math.round(props.report.Stat().Price())}</div>
    </div>
    )

}
