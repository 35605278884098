import React, { useContext, useState } from "react";
import { Payment } from "../../../../domain/payment/payment";
import { DotsVertical, Edit, Trash } from 'tabler-icons-react'
import { UseCasesContext } from "../../../../context/useCases";
import { Link, useParams } from "react-router-dom";
import { RecruiterReportSource } from "../../../../domain/report/recruitersReport";
import { report } from "process";

export const TableRow = (props: { report: RecruiterReportSource, recruiters: {name: string, id: number}[], last: boolean }) => {
    const {id} = useParams()
    const [showMenu, setShowMenu] = useState(false)
    let useCases = useContext(UseCasesContext)

    return (<tr>
        <td>{props.report.SourceName()}</td>
        <td>{Math.round(props.report.NegotationsCount())}</td>
        <td>{Math.round(props.report.SpentMoney())}</td>
        <td>{Math.round(props.report.Payments())}</td>
        {
            props.recruiters.map((recruiter)=>{
                for (let i = 0; i <  props.report.Detalization().length; i++) {
                    let item = props.report.Detalization()[i]
                    if (item.RecruiterId() == recruiter.id) {
                        return <React.Fragment>
                            <td>{item.NegtationsCount()}</td>
                            <td>{Math.round(item.SpentMoney())}</td>
                            <td>{Math.round(item.Payments())}</td>
                        </React.Fragment>
                    }
                }
            })
        }
    </tr>)
}
