import React, { useContext, useEffect, useState } from "react"
import { Menu } from "../../components/menu"


export const Settings = () => {

    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-4">
                            <h4 className="fw-bold py-3 mb-4">Настройки</h4>
                        </div>
                    </div>
                    <a href="#" style={{maxWidth:"250px"}} onClick={(e)=>{e.preventDefault(); fetch("https://hrstat.ru/api/leads/clear"); alert("Процесс запущен, данные обновятся через ~ 10 минут")}} className="btn btn-primary waves-effect waves-light">Актуализировать данные</a>
                </div>
            </div>
        </div>
    )

}