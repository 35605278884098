import { useContext, useEffect, useState } from "react"
import { UseCasesContext } from "../../../../context/useCases"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import { useParams } from "react-router-dom"
import { TableRow } from "./tableRow"
import { Menu } from "../../../components/menu"
import { ReportSourcesItem, Sums } from "../../../../domain/report/sources"
import { TableRowLast } from "./tableRowLast"
import { ReportAutoRecruitersReport } from "../../../../repository/api/generated"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import { DateTime } from "luxon"
import { ru } from 'date-fns/locale/ru';
registerLocale('ru', ru)


export const RecruitersAutoReport = () => {
    const [reports, setReports] = useState<ReportAutoRecruitersReport | null>(null)
    let { id } = useParams();

    const [monthsList, setMonthsList] = useState<{ name: string, date: string }[]>([])
    const [weekStartDate, setWeekStartDate] = useState<Date>(new Date())
    const [dateFilterType, setDateFilterType] = useState<"day" | "week" | "month">("week")

    const [filter, setFilter] = useState<{
        from: Date | null,
        to: Date | null,
    }>({
        from: null,
        to: null,
    })

    const dateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let date = new Date(e.target.value)
        setFilter({
            ...filter,
            [e.target.name]: date
        })
    }

    const days = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС']
    const months = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"]

    const getDates = () => {
        let monthsList: { name: string, date: string }[] = []
        let date = new Date()
        date.setDate(date.getDate() - 180);
        while (date.getDate() !== 1) {
            date.setDate(date.getDate() - 1)
        }
        for (let i = 0; i < 7; i++) {
            if (date) {
                monthsList.push({
                    name: months[date.getMonth()] + " " + date.getFullYear(),
                    date: formatDate(date)
                })
            }
            date.setDate(date.getDate() + 30);
            if (date.getDate() < 15) {
                while (date.getDate() !== 1) {
                    date.setDate(date.getDate() - 1)
                }
            } else {
                while (date.getDate() !== 1) {
                    date.setDate(date.getDate() + 1)
                }
            }
        }
        setMonthsList([...monthsList])
        console.log(monthsList[monthsList.length - 1].date)
        if (!filter.from) {
            monthChange(monthsList[monthsList.length - 1].date)
        }
    }

    const monthChange = (value: string) => {
        let lastDay = new Date(value)

        lastDay.setMonth(lastDay.getMonth() + 1)
        lastDay.setDate(lastDay.getDate() - 1)
        lastDay.setHours(23)
        lastDay.setMinutes(59)
        lastDay.setSeconds(59)
        setFilter({
            ...filter,
            from: new Date(value),
            to: lastDay,
        })
    }




    let useCases = useContext(UseCasesContext)

    const payments = useTypedSelector(({ payment }) => {
        return payment
    })

    const readReport = async () => {
        let result = await useCases?.reportUseCase.ReadRecruitersAutoReport(filter.from!, filter.to!)
        if (result instanceof Error) {

        } else {
            console.log(result)
            setReports(result!)
        }
    }

    useEffect(() => {
        if (filter.from && filter.to) {
            readReport()
        }
        getDates()
    }, [filter])

    useEffect(() => {
        useCases?.sourceUseCase.ReadSources()
    }, [])


    const formatDate = (day: Date) => {
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return yyyy + "-" + mm + '-' + dd;
    }

    useEffect(() => {
        if (dateFilterType == "week") {
            let from = weekStartDate
            from.setHours(3)
            from.setMinutes(0)
            from.setSeconds(0)
            let to = DateTime.fromJSDate(from).plus({ days: 6 }).toJSDate();
            to.setHours(23)
            to.setMinutes(59)
            to.setSeconds(59)

            setFilter({
                from: from,
                to: to,
            })
        }
    }, [weekStartDate, dateFilterType])

    const oneDayFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.value)

        let from = new Date(e.target.value)
        from.setHours(3)
        from.setMinutes(0)
        from.setSeconds(0)

        let to = new Date(from)


        to.setHours(23)
        to.setMinutes(59)
        to.setSeconds(59)
        setFilter({
            ...filter,
            from: from,
            to: to,
        })
    }



    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-4">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Отчет по рекрутерам /</span> Автоматический отчет по рекрутерам</h4>
                        </div>

                        <div className="card mb-4">
                            <div className="card-body">
                                <div className="col-md-6 col-lg-4 mb-2">
                                    <small>Тип фильтра</small>
                                    <div className="mt-3">
                                        <div className="btn-group" role="group" aria-label="Basic example">
                                            <button type="button" onClick={()=>{setDateFilterType("month")}} className={dateFilterType == "month"? "btn btn-primary waves-effect waves-light":"btn btn-label-secondary waves-effect waves-light"}>Месяц</button>
                                            <button type="button" onClick={()=>{setDateFilterType("week")}} className={dateFilterType == "week"? "btn btn-primary waves-effect waves-light":"btn btn-label-secondary waves-effect waves-light"}>Неделя</button>
                                            <button type="button" onClick={()=>{setDateFilterType("day")}} className={dateFilterType == "day"? "btn btn-primary waves-effect waves-light":"btn btn-label-secondary waves-effect waves-light"}>День</button>
                                        </div>
                                    </div>
                                </div>
                                {dateFilterType == "month" ? <div className="row mb-3">
                                    <div className="col-12 col-sm-6 col-lg-4 justify-content-end">
                                        <label className="form-label">Месяц:</label>
                                        {filter.from ? <select value={formatDate(filter.from!)} onChange={(e) => monthChange(e.target.value)} name="from" className="form-select mb-3">
                                            {monthsList.map((monthItem) => {
                                                return <option value={monthItem.date}>{monthItem.name}</option>
                                            })}
                                        </select> : <></>}
                                    </div>
                                </div> : ""}

                                {dateFilterType == "week" ? <div className="row mb-3">
                                    <div className="col-12 col-sm-6 col-lg-4 justify-content-end" style={{ zIndex: 990000 }}>
                                        <label className="form-label">Неделя:</label><br />
                                        <DatePicker
                                            selected={weekStartDate}
                                            locale={"ru"}
                                            onChange={(date) => setWeekStartDate(date!)}
                                            dateFormat="I/R"
                                            excludeDateIntervals={[
                                            ]}
                                            showWeekNumbers
                                            showWeekPicker
                                        />
                                    </div>
                                </div> : <></>}

                                {dateFilterType == "day" ?
                                    <div className="col-12 col-sm-6 col-lg-4 justify-content-end">
                                        <label htmlFor={"day-input"} className="form-label">День</label>
                                        <input
                                            type={"date"}
                                            className="form-control"
                                            name={"day-input"}
                                            placeholder={"Выберите дату"}
                                            autoFocus={true}
                                            value={formatDate(filter.from ? filter.from : new Date())}
                                            onChange={oneDayFilterChange}
                                        />
                                    </div>
                                    : <></>}

                            </div>
                        </div>


                        <div className="table_scroll_main">

                            <div className="table_scroll table">
                                <div className="css_table">
                                    <div className="css_thead">
                                        <div className="css_tr">
                                            <div className="css_th">ФИО <br />рекрутера</div>
                                            <div className="css_th">Кол-во <br />сделок</div>

                                            <div className="css_th">Успешное <br />собеседование</div>
                                            <div className="css_th">Конверсия в <br />успешное собеседование</div>

                                            <div className="css_th">Записан на <br />Welcome</div>
                                            <div className="css_th">Конверсия в запись <br />на Welcome</div>

                                            <div className="css_th">Явка на <br />Welcome</div>
                                            <div className="css_th">Конверсия в явку <br />на Welcome</div>

                                            <div className="css_th">Сдал <br />экзамен</div>
                                            <div className="css_th">Конверсия в успе- <br />шный экзамен</div>

                                            <div className="css_th">Вышел на <br />стажировку</div>
                                            <div className="css_th">Конверсия в <br />стажировку</div>

                                            <div className="css_th">Вышел на <br />ИС</div>
                                            <div className="css_th">Конверсия в <br />ИС</div>

                                            <div className="css_th"> Сквозная конвер-<br />сия в ИС</div>

                                            <div className="css_th"> Вышел в <br />штат</div>
                                            <div className="css_th"> Конверсия в <br />штат</div>
                                        </div>
                                    </div>
                                    <div className="css_tbody">
                                        {reports?.items && reports?.items?.map((report, index) => {
                                            return <TableRow report={report} last={reports?.items!.length - 1 == index ? true : false} />
                                        })}
                                        {reports?.sum ? <TableRowLast report={reports?.sum} /> : <></>}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    )

}