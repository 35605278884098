export class User {
    private id: UniqueId;
    private name: string;
    private login: string;
    private pass: string;
    private accessList: string[];
    private createdAt: Date;
    private modifiedAt: Date;

    constructor(id: UniqueId, name: string, login: string, pass: string, accessList: string[], createdAt: Date, modifiedAt: Date) {
        this.id = id;
        this.name = name;
        this.login = login;
        this.pass = pass;
        this.accessList = accessList;
        this.createdAt = createdAt;
        this.modifiedAt = modifiedAt;
    }

    public Id = (): UniqueId => {
        return this.id
    }

    public Name = (): string => {
        return this.name
    }

    public Login = (): string => {
        return this.login
    }

    public Pass = (): string => {
        return this.pass
    }

    public AccessList = (): string[] => {
        return this.accessList
    }

    public CreatedAt = (): Date => {
        return this.createdAt
    }

    public ModifiedAt = (): Date => {
        return this.modifiedAt
    }

}