import { Dispatch } from "react"
import { PaymentActionTypes } from "../action-types";
import { PaymentActions } from "../actions"
import { Payment } from "../../domain/payment/payment";

export const SendPaymentListRequest = () => {
    return async (dispatch: Dispatch<PaymentActions>) => {
        dispatch({
            type: PaymentActionTypes.PAYMENT_REQUEST_SEND,
          });
    }
}

export const PaymentListSuccess = (kvizes: Payment[]) => {
    return async (dispatch: Dispatch<PaymentActions>) => {
        dispatch({
            type: PaymentActionTypes.PAYMENT_SUCCESS,
            payload: kvizes,
        });
    }
}

export const PaymentListError = (error:string) => {
    return async (dispatch: Dispatch<PaymentActions>) => {
        dispatch({
            type: PaymentActionTypes.PAYMENT_ERROR,
            payload: error,
        });
    }
}

export const PaymentCreateRequest = () => {
    return async (dispatch: Dispatch<PaymentActions>) => {
        dispatch({
            type: PaymentActionTypes.PAYMENT_CREATE_REQUEST,
          });
    }
}

export const PaymentCreateSuccess = (payment: Payment) => {
    return async (dispatch: Dispatch<PaymentActions>) => {
        dispatch({
            type: PaymentActionTypes.PAYMENT_CREATE_SUCCESS,
            payload: payment,
          });
    }
}

export const PaymentCreateError = (message: string) => {
    return async (dispatch: Dispatch<PaymentActions>) => {
        dispatch({
            type: PaymentActionTypes.PAYMENT_CREATE_ERROR,
            payload: message,
          });
    }
}


export const PaymentUpdateRequest = () => {
    return async (dispatch: Dispatch<PaymentActions>) => {
        dispatch({
            type: PaymentActionTypes.PAYMENT_UPDATE_REQUEST,
          });
    }
}

export const PaymentUpdateSuccess = (category: Payment) => {
    return async (dispatch: Dispatch<PaymentActions>) => {
        dispatch({
            type: PaymentActionTypes.PAYMENT_UPDATE_SUCCESS,
            payload: category,
          });
    }
}

export const PaymentUpdateError = (message: string) => {
    return async (dispatch: Dispatch<PaymentActions>) => {
        dispatch({
            type: PaymentActionTypes.PAYMENT_UPDATE_ERROR,
            payload: message,
          });
    }
}


export const PaymentDeleteRequest = () => {
    return async (dispatch: Dispatch<PaymentActions>) => {
        dispatch({
            type: PaymentActionTypes.PAYMENT_DELETE_REQUEST,
          });
    }
}

export const PaymentDeleteSuccess = (id: UniqueId) => {
    return async (dispatch: Dispatch<PaymentActions>) => {
        dispatch({
            type: PaymentActionTypes.PAYMENT_DELETE_SUCCESS,
            payload: id,
          });
    }
}

export const PaymentDeleteError = (message: string) => {
    return async (dispatch: Dispatch<PaymentActions>) => {
        dispatch({
            type: PaymentActionTypes.PAYMENT_DELETE_ERROR,
            payload: message,
          });
    }
}