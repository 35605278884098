import { MentorsRepository } from "../../repository/api/mentor/mentor";
import { actionCreators } from "../../state";

export class MentorUseCases {
    private api: MentorsRepository;
    private stateActions: typeof actionCreators;

    constructor(api: MentorsRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadMentors = async () => {
        this.stateActions.SendMentorListRequest()
        let response = await this.api.ReadMentors()
        if (response instanceof Error) {
            this.stateActions.MentorListError(response.message)
            return
        } else {
            this.stateActions.MentorListSuccess(response)
        }
    }

    public ReadUsersOfAmo = async () => {
        let response = await this.api.ReadUsersOfAmo()
        return response
    }

    public ManualResumeProcess = async (url: string, responsibleId: number) => {
        let response = await this.api.ManualResumeProcess(url, responsibleId)
        return response
    }
}