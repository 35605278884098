import { PaymentsRepository } from "../../repository/api/payment/payment";
import { actionCreators } from "../../state";

export class PaymentUseCases {
    private api: PaymentsRepository;
    private stateActions: typeof actionCreators;

    constructor(api: PaymentsRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadPayments = async (from: Date, to: Date, mentorId: number, recruiterId: number, sourceId: number) => {
        this.stateActions.SendPaymentListRequest()
        let response = await this.api.ReadPayments(from, to, mentorId, recruiterId, sourceId)
        if (response instanceof Error) {
            this.stateActions.PaymentListError(response.message)
            return
        } else {
            this.stateActions.PaymentListSuccess(response)
        }
    }

    public ReadPaymentById = async (id: UniqueId) => {
        let response = await this.api.ReadPaymentById(id)
        return response
    }

    public CreatePayment = async (startDate:Date, endDate: Date, amount: number, entityId: number, paymentType: string, sourceId: number, typeOfSourceId: number, vacancyId: UniqueId, callback?: ()=>void) => {
        if (vacancyId === "") {
            vacancyId = "00000000-0000-0000-0000-000000000000"
        }
        this.stateActions.PaymentCreateRequest()
        let response = await this.api.CreatePayment(startDate, endDate, amount, entityId, paymentType, sourceId, typeOfSourceId, vacancyId)
        if (response instanceof Error) {
            this.stateActions.PaymentCreateError(response.message)
            return
        } else {
            this.stateActions.PaymentCreateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

    public UpdatePayment = async (id: UniqueId, startDate:Date, endDate: Date, amount: number, entityId: number, paymentType: string, sourceId: number, typeOfSourceId: number, vacancyId: UniqueId, callback?: ()=>void) => {
        if (vacancyId === "") {
            vacancyId = "00000000-0000-0000-0000-000000000000"
        }
        this.stateActions.PaymentUpdateRequest()
        let response = await this.api.UpdatePayment(id, startDate, endDate, amount, entityId, paymentType, sourceId, typeOfSourceId, vacancyId)
        if (response instanceof Error) {
            this.stateActions.PaymentUpdateError(response.message)
            return
        } else {
            this.stateActions.PaymentUpdateSuccess(response)
            if (callback) {
                callback()
            }
        }
    }

    public DeletePayment = async (id: UniqueId) => {
        this.stateActions.PaymentDeleteRequest()
        let response = await this.api.DeletePayment(id)
        if (response instanceof Error) {
            this.stateActions.PaymentDeleteError(response.message)
            return
        } else {
            this.stateActions.PaymentDeleteSuccess(id)
        }
    }

}