import { SourcesRepository } from "../../repository/api/source/source";
import { actionCreators } from "../../state";

export class SourceUseCases {
    private api: SourcesRepository;
    private stateActions: typeof actionCreators;

    constructor(api: SourcesRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadSources = async () => {
        this.stateActions.SendSourceListRequest()
        let response = await this.api.ReadSources()
        if (response instanceof Error) {
            this.stateActions.SourceListError(response.message)
            return
        } else {
            this.stateActions.SourceListSuccess(response)
        }
    }

}