import { useContext, useRef, useState } from "react";
import { UseCasesContext } from "../../../../context/useCases";
import { Link, useParams } from "react-router-dom";
import { ReportCandidatesReport } from "../../../../repository/api/generated";

export const TableRow = (props: { report: ReportCandidatesReport, last: boolean }) => {
    const { id } = useParams()
    const [showMenu, setShowMenu] = useState(false)
    let useCases = useContext(UseCasesContext)
    const rowRef = useRef<any>(null);

    return (<><div className="css_tr" ref={rowRef}>
        <div className="css_sd css_sd_nowrap"><b>Кол-во приглашенных</b></div>
        <div className="css_sd css_sd_nowrap">{props.report.welcome_count}</div>
    </div>
        <div className="css_tr" ref={rowRef}>
            <div className="css_sd css_sd_nowrap"><b>Кол-во вышедших</b></div>
            <div className="css_sd css_sd_nowrap">{props.report.welcome_appearence_count}</div>
        </div>
        <div className="css_tr" ref={rowRef}>
            <div className="css_sd css_sd_nowrap"><b>Явка на Welcome</b></div>
            <div className="css_sd css_sd_nowrap">{props.report.welcome_appearence_conversion}%</div>
        </div>
        <div className="css_tr" ref={rowRef}>
            <div className="css_sd css_sd_nowrap"><b>Отказы на этапе Welcome</b></div>
            <div className="css_sd css_sd_nowrap">{props.report.welcome_deny_count}</div>
        </div>
        <div className="css_tr" ref={rowRef}>
            <div className="css_sd css_sd_nowrap"><b>Кол-во приступивших к обучению</b></div>
            <div className="css_sd css_sd_nowrap">{props.report.study_started_count}</div>
        </div>
        <div className="css_tr" ref={rowRef}>
            <div className="css_sd css_sd_nowrap"><b>Конверсия в обучение</b></div>
            <div className="css_sd css_sd_nowrap">{props.report.study_conversion}%</div>
        </div>
        <div className="css_tr" ref={rowRef}>
            <div className="css_sd css_sd_nowrap"><b>Отказы на этапе обучения</b></div>
            <div className="css_sd css_sd_nowrap">{props.report.study_deny_count}</div>
        </div>
        <div className="css_tr" ref={rowRef}>
            <div className="css_sd css_sd_nowrap"><b>Кол-во сдавших экзамен</b></div>
            <div className="css_sd css_sd_nowrap">{props.report.passed_exam_count}</div>
        </div>
        <div className="css_tr" ref={rowRef}>
            <div className="css_sd css_sd_nowrap"><b>Конверсия сдавших экзамен</b></div>
            <div className="css_sd css_sd_nowrap">{props.report.passed_exam_conversion}%</div>
        </div>
        <div className="css_tr" ref={rowRef}>
            <div className="css_sd css_sd_nowrap"><b>Кол-во вышедших на стажировку</b></div>
            <div className="css_sd css_sd_nowrap">{props.report.internship_started_count}</div>
        </div>
        <div className="css_tr" ref={rowRef}>
            <div className="css_sd css_sd_nowrap"><b>Конверсия вышедших на стажировку</b></div>
            <div className="css_sd css_sd_nowrap">{props.report.internship_started_conversion}%</div>
        </div>
        <div className="css_tr" ref={rowRef}>
            <div className="css_sd css_sd_nowrap"><b>Отказы на этапе стажировки</b></div>
            <div className="css_sd css_sd_nowrap">{props.report.internship_deny_count}</div>
        </div>
        <div className="css_tr" ref={rowRef}>
            <div className="css_sd css_sd_nowrap"><b>Кол-во вышедших на ИС</b></div>
            <div className="css_sd css_sd_nowrap">{props.report.probation_started_count}</div>
        </div>
        <div className="css_tr" ref={rowRef}>
            <div className="css_sd css_sd_nowrap"><b>Конверсия вышедших на ИС</b></div>
            <div className="css_sd css_sd_nowrap">{props.report.probation_started_conversion}%</div>
        </div>
        <div className="css_tr" ref={rowRef}>
            <div className="css_sd css_sd_nowrap"><b>Сквозная конверсия в ИС </b></div>
            <div className="css_sd css_sd_nowrap">{props.report.end_to_end_probation_conversion}%</div>
        </div>
        <div className="css_tr" ref={rowRef}>
            <div className="css_sd css_sd_nowrap"><b>Отказы на ИС</b></div>
            <div className="css_sd css_sd_nowrap">{props.report.probation_deny_count}</div>
        </div>
        <div className="css_tr" ref={rowRef}>
            <div className="css_sd css_sd_nowrap"><b>Вышли в штат</b></div>
            <div className="css_sd css_sd_nowrap">{props.report.state_count}</div>
        </div>
        <div className="css_tr" ref={rowRef}>
            <div className="css_sd css_sd_nowrap"><b>Конверсия вышедших в штат</b></div>
            <div className="css_sd css_sd_nowrap">{props.report.state_count_conversion}</div>
        </div>
    </>
    )

}
