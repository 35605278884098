import { Dispatch } from "react"
import { RecruiterActionTypes } from "../action-types";
import { RecruiterActions } from "../actions"
import { Recruiter } from "../../domain/recruiter/recruiter";

export const SendRecruiterListRequest = () => {
    return async (dispatch: Dispatch<RecruiterActions>) => {
        dispatch({
            type: RecruiterActionTypes.RECRUITER_REQUEST_SEND,
          });
    }
}

export const RecruiterListSuccess = (kvizes: Recruiter[]) => {
    return async (dispatch: Dispatch<RecruiterActions>) => {
        dispatch({
            type: RecruiterActionTypes.RECRUITER_SUCCESS,
            payload: kvizes,
        });
    }
}

export const RecruiterListError = (error:string) => {
    return async (dispatch: Dispatch<RecruiterActions>) => {
        dispatch({
            type: RecruiterActionTypes.RECRUITER_ERROR,
            payload: error,
        });
    }
}

