import axios from "axios";
import { ReportInterface } from "../../../useCase/report/adapters/Interface";
import { Configuration, ReportApi, ReportAutoRecruitersReport, ReportCandidatesReport } from "../generated";
import { ReportSources, ReportSourcesItem, Payments, SourceType, Sums, Report, SumItem } from "../../../domain/report/sources";
import { RecruiterReportDetalization, RecruiterReportSource, RecruiterReportSums, RecruitersReport } from "../../../domain/report/recruitersReport";
import { MentorReportItem, MentorsReport } from "../../../domain/report/mentorsReport";

export class ReportsRepository implements ReportInterface {
    private service: ReportApi;

    constructor() {
        this.service = new ReportApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async ReportSourcesClassic(from: Date, to: Date): Promise<{items: ReportSourcesItem[], sums: Sums} | Error> {
        try {
            let response = await this.service.apiReportClassicGet(from.toISOString(), to.toISOString().replace('T20:59:59.000Z', 'T23:59:59.000Z'), { headers: { "Authorization": localStorage.getItem("token") } })
            let items: ReportSourcesItem[] = [];
            if (response.status === 200) {
                
                Object.keys(response.data.items!).map((key)=>{
                    let inputItem = response.data.items![key]
                    let payments = new Payments(inputItem.payments!.traffic_payments!, inputItem.payments!.recruiter_payments!, inputItem.payments!.mentor_payments!, inputItem.payments!.hr_manager_payments! )
                    let interview = new SourceType(inputItem.interview?.interview_count!, inputItem.interview?.interview_percent!, inputItem.interview?.interview_price! )
                    let comedToWelcome = new SourceType(inputItem.comed_to_welcome?.comed_to_welcome_count!, inputItem.comed_to_welcome?.comed_to_welcome_percent!, inputItem.comed_to_welcome?.comed_to_welcome_price! )
                    let welcome = new SourceType(inputItem.welcome?.welcome_count!, inputItem.welcome?.welcome_percent!, inputItem.welcome?.welcome_price! )
                    let education = new SourceType(inputItem.education?.education_count!, inputItem.education?.education_percent!, inputItem.education?.education_price! )
                    let exam = new SourceType(inputItem.exam?.exam_count!, inputItem.exam?.exam_percent!, inputItem.exam?.exam_price! )
                    let stat = new SourceType(inputItem.stat?.stat_count!, inputItem.stat?.stat_percent!, inputItem.stat?.stat_price! )
                    let trial = new SourceType(inputItem.trial?.trial_count!, inputItem.trial?.trial_percent!, inputItem.trial?.trial_price! )
                    let item = new ReportSourcesItem(inputItem.source_id!, inputItem.source_name!, inputItem.negotations_count!, inputItem.negotation_price!, payments, interview, welcome, comedToWelcome, education, exam, stat, trial )
                    items.push(item)
                })
                let sum = new Sums(new SumItem(0, response.data.sums?.traffic?.payment!, 0), new SumItem(0, response.data.sums?.recruiter?.payment!, 0), new SumItem(0, response.data.sums?.mentor?.payment!, 0), new SumItem(0, response.data.sums?.hr_manager?.payment!, 0),  new SumItem(response.data.sums?.negotations?.count!, response.data.sums?.negotations?.price!, response.data.sums?.negotations?.percent!), new SumItem(response.data.sums?.interview?.count!, response.data.sums?.interview?.price! , response.data.sums?.interview?.percent!), new SumItem(response.data.sums?.welcome?.count!, response.data.sums?.welcome?.price!, response.data.sums?.welcome?.percent!), new SumItem(response.data.sums?.comedToWelcome?.count!, response.data.sums?.comedToWelcome?.price!, response.data.sums?.comedToWelcome?.percent!), new SumItem(response.data.sums?.education?.count!, response.data.sums?.education?.price!, response.data.sums?.education?.percent!), new SumItem(response.data.sums?.exam?.count!, response.data.sums?.exam?.price!, response.data.sums?.exam?.percent!), new SumItem(response.data.sums?.trial?.count!, response.data.sums?.trial?.price!, response.data.sums?.trial?.percent!), new SumItem(response.data.sums?.stat?.count!, response.data.sums?.stat?.price!, response.data.sums?.stat?.percent!))
                console.log(response.data)
                return {items: items, sums: sum}
            }
        } catch (e) {
            console.log(e)
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async ReportSourcesAcademy(from: Date, to: Date): Promise<{items: ReportSourcesItem[], sums: Sums} | Error> {
        try {
            let response = await this.service.apiReportAcademyGet(from.toISOString(), to.toISOString().replace('T20:59:59.000Z', 'T23:59:59.000Z'), { headers: { "Authorization": localStorage.getItem("token") } })
            let items: ReportSourcesItem[] = [];
            if (response.status === 200) {
                
                Object.keys(response.data.items!).map((key)=>{
                    let inputItem = response.data.items![key]
                    let payments = new Payments(inputItem.payments!.traffic_payments!, inputItem.payments!.recruiter_payments!, inputItem.payments!.mentor_payments!, inputItem.payments!.hr_manager_payments! )
                    let interview = new SourceType(inputItem.interview?.interview_count!, inputItem.interview?.interview_percent!, inputItem.interview?.interview_price! )
                    let comedToWelcome = new SourceType(inputItem.comed_to_welcome?.comed_to_welcome_count!, inputItem.comed_to_welcome?.comed_to_welcome_percent!, inputItem.comed_to_welcome?.comed_to_welcome_price! )
                    let welcome = new SourceType(inputItem.welcome?.welcome_count!, inputItem.welcome?.welcome_percent!, inputItem.welcome?.welcome_price! )
                    let education = new SourceType(inputItem.education?.education_count!, inputItem.education?.education_percent!, inputItem.education?.education_price! )
                    let exam = new SourceType(inputItem.exam?.exam_count!, inputItem.exam?.exam_percent!, inputItem.exam?.exam_price! )
                    let stat = new SourceType(inputItem.stat?.stat_count!, inputItem.stat?.stat_percent!, inputItem.stat?.stat_price! )
                    let trial = new SourceType(inputItem.trial?.trial_count!, inputItem.trial?.trial_percent!, inputItem.trial?.trial_price! )
                    let item = new ReportSourcesItem(inputItem.source_id!, inputItem.source_name!, inputItem.negotations_count!, inputItem.negotation_price!, payments, interview, welcome, comedToWelcome, education, exam, stat, trial )
                    items.push(item)
                })
                let sum = new Sums(new SumItem(0, response.data.sums?.traffic?.payment!, 0), new SumItem(0, response.data.sums?.recruiter?.payment!, 0), new SumItem(0, response.data.sums?.mentor?.payment!, 0), new SumItem(0, response.data.sums?.hr_manager?.payment!, 0),  new SumItem(response.data.sums?.negotations?.count!, response.data.sums?.negotations?.price!, response.data.sums?.negotations?.percent!), new SumItem(response.data.sums?.interview?.count!, response.data.sums?.interview?.price! , response.data.sums?.interview?.percent!), new SumItem(response.data.sums?.welcome?.count!, response.data.sums?.welcome?.price!, response.data.sums?.welcome?.percent!), new SumItem(response.data.sums?.comedToWelcome?.count!, response.data.sums?.comedToWelcome?.price!, response.data.sums?.comedToWelcome?.percent!), new SumItem(response.data.sums?.education?.count!, response.data.sums?.education?.price!, response.data.sums?.education?.percent!), new SumItem(response.data.sums?.exam?.count!, response.data.sums?.exam?.price!, response.data.sums?.exam?.percent!), new SumItem(response.data.sums?.trial?.count!, response.data.sums?.trial?.price!, response.data.sums?.trial?.percent!), new SumItem(response.data.sums?.stat?.count!, response.data.sums?.stat?.price!, response.data.sums?.stat?.percent!))
                
                console.log(response.data)
                return {items: items, sums: sum}
            }
        } catch (e) {
            console.log(e)
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async ReportSourcesAll(from: Date, to: Date): Promise<{items: ReportSourcesItem[], sums: Sums} | Error> {
        try {
            let response = await this.service.apiReportAllSourcesGet(from.toISOString(), to.toISOString().replace('T20:59:59.000Z', 'T23:59:59.000Z').replace('T20:59:59.000Z', 'T23:59:59.000Z'), { headers: { "Authorization": localStorage.getItem("token") } })
            let items: ReportSourcesItem[] = [];
            if (response.status === 200) {
                
                Object.keys(response.data.items!).map((key)=>{
                    let inputItem = response.data.items![key]
                    let payments = new Payments(inputItem.payments!.traffic_payments!, inputItem.payments!.recruiter_payments!, inputItem.payments!.mentor_payments!, inputItem.payments!.hr_manager_payments! )
                    let interview = new SourceType(inputItem.interview?.interview_count!, inputItem.interview?.interview_percent!, inputItem.interview?.interview_price! )
                    let comedToWelcome = new SourceType(inputItem.comed_to_welcome?.comed_to_welcome_count!, inputItem.comed_to_welcome?.comed_to_welcome_percent!, inputItem.comed_to_welcome?.comed_to_welcome_price! )
                    let welcome = new SourceType(inputItem.welcome?.welcome_count!, inputItem.welcome?.welcome_percent!, inputItem.welcome?.welcome_price! )
                    let education = new SourceType(inputItem.education?.education_count!, inputItem.education?.education_percent!, inputItem.education?.education_price! )
                    let exam = new SourceType(inputItem.exam?.exam_count!, inputItem.exam?.exam_percent!, inputItem.exam?.exam_price! )
                    let stat = new SourceType(inputItem.stat?.stat_count!, inputItem.stat?.stat_percent!, inputItem.stat?.stat_price! )
                    let trial = new SourceType(inputItem.trial?.trial_count!, inputItem.trial?.trial_percent!, inputItem.trial?.trial_price! )
                    let item = new ReportSourcesItem(inputItem.source_id!, inputItem.source_name!, inputItem.negotations_count!, inputItem.negotation_price!, payments, interview, welcome, comedToWelcome, education, exam, stat, trial )
                    items.push(item)
                })
                let sum = new Sums(new SumItem(0, response.data.sums?.traffic?.payment!, 0), new SumItem(0, response.data.sums?.recruiter?.payment!, 0), new SumItem(0, response.data.sums?.mentor?.payment!, 0), new SumItem(0, response.data.sums?.hr_manager?.payment!, 0),  new SumItem(response.data.sums?.negotations?.count!, response.data.sums?.negotations?.price!, response.data.sums?.negotations?.percent!), new SumItem(response.data.sums?.interview?.count!, response.data.sums?.interview?.price! , response.data.sums?.interview?.percent!), new SumItem(response.data.sums?.welcome?.count!, response.data.sums?.welcome?.price!, response.data.sums?.welcome?.percent!), new SumItem(response.data.sums?.comedToWelcome?.count!, response.data.sums?.comedToWelcome?.price!, response.data.sums?.comedToWelcome?.percent!), new SumItem(response.data.sums?.education?.count!, response.data.sums?.education?.price!, response.data.sums?.education?.percent!), new SumItem(response.data.sums?.exam?.count!, response.data.sums?.exam?.price!, response.data.sums?.exam?.percent!), new SumItem(response.data.sums?.trial?.count!, response.data.sums?.trial?.price!, response.data.sums?.trial?.percent!), new SumItem(response.data.sums?.stat?.count!, response.data.sums?.stat?.price!, response.data.sums?.stat?.percent!))
                console.log(response.data)
                return {items: items, sums: sum,}
            }
        } catch (e) {
            console.log(e)
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async ReportKaAll(from: Date, to: Date): Promise<{items: ReportSourcesItem[], comment: string, sums: Sums} | Error> {
        try {
            let response = await this.service.apiReportKaAllGet(from.toISOString(), to.toISOString().replace('T20:59:59.000Z', 'T23:59:59.000Z'), { headers: { "Authorization": localStorage.getItem("token") } })
            let items: ReportSourcesItem[] = [];
            if (response.status === 200) {
                
                Object.keys(response.data.items!).map((key)=>{
                    let inputItem = response.data.items![key]
                    let payments = new Payments(inputItem.payments!.traffic_payments!, inputItem.payments!.recruiter_payments!, inputItem.payments!.mentor_payments!, inputItem.payments!.hr_manager_payments! )
                    let interview = new SourceType(inputItem.interview?.interview_count!, inputItem.interview?.interview_percent!, inputItem.interview?.interview_price! )
                    let comedToWelcome = new SourceType(inputItem.comed_to_welcome?.comed_to_welcome_count!, inputItem.comed_to_welcome?.comed_to_welcome_percent!, inputItem.comed_to_welcome?.comed_to_welcome_price! )
                    let welcome = new SourceType(inputItem.welcome?.welcome_count!, inputItem.welcome?.welcome_percent!, inputItem.welcome?.welcome_price! )
                    let education = new SourceType(inputItem.education?.education_count!, inputItem.education?.education_percent!, inputItem.education?.education_price! )
                    let exam = new SourceType(inputItem.exam?.exam_count!, inputItem.exam?.exam_percent!, inputItem.exam?.exam_price! )
                    let stat = new SourceType(inputItem.stat?.stat_count!, inputItem.stat?.stat_percent!, inputItem.stat?.stat_price! )
                    let trial = new SourceType(inputItem.trial?.trial_count!, inputItem.trial?.trial_percent!, inputItem.trial?.trial_price! )
                    let item = new ReportSourcesItem(inputItem.source_id!, inputItem.source_name!, inputItem.negotations_count!, inputItem.negotation_price!, payments, interview, welcome, comedToWelcome, education, exam, stat, trial, {profit: inputItem.profit?.profit!, romi: inputItem.profit?.romi!,} )
                    items.push(item)
                })
                let sum = new Sums(new SumItem(0, response.data.sums?.traffic?.payment!, 0), new SumItem(0, response.data.sums?.recruiter?.payment!, 0), new SumItem(0, response.data.sums?.mentor?.payment!, 0), new SumItem(0, response.data.sums?.hr_manager?.payment!, 0),  new SumItem(response.data.sums?.negotations?.count!, response.data.sums?.negotations?.price!, response.data.sums?.negotations?.percent!), new SumItem(response.data.sums?.interview?.count!, response.data.sums?.interview?.price! , response.data.sums?.interview?.percent!), new SumItem(response.data.sums?.welcome?.count!, response.data.sums?.welcome?.price!, response.data.sums?.welcome?.percent!), new SumItem(response.data.sums?.comedToWelcome?.count!, response.data.sums?.comedToWelcome?.price!, response.data.sums?.comedToWelcome?.percent!), new SumItem(response.data.sums?.education?.count!, response.data.sums?.education?.price!, response.data.sums?.education?.percent!), new SumItem(response.data.sums?.exam?.count!, response.data.sums?.exam?.price!, response.data.sums?.exam?.percent!), new SumItem(response.data.sums?.trial?.count!, response.data.sums?.trial?.price!, response.data.sums?.trial?.percent!), new SumItem(response.data.sums?.stat?.count!, response.data.sums?.stat?.price!, response.data.sums?.stat?.percent!), {profit: response.data.sums?.profit?.profit!, romi:  response.data.sums?.profit?.romi!,})
                console.log(response.data)
                return {items: items, comment: response.data.comment!, sums: sum}
            }
        } catch (e) {
            console.log(e)
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async ReportAkcAll(from: Date, to: Date): Promise<{items: ReportSourcesItem[], sums: Sums} | Error> {
        try {
            let response = await this.service.apiReportAkcAllGet(from.toISOString(), to.toISOString().replace('T20:59:59.000Z', 'T23:59:59.000Z'), { headers: { "Authorization": localStorage.getItem("token") } })
            let items: ReportSourcesItem[] = [];
            if (response.status === 200) {
                
                Object.keys(response.data.items!).map((key)=>{
                    let inputItem = response.data.items![key]
                    let payments = new Payments(inputItem.payments!.traffic_payments!, inputItem.payments!.recruiter_payments!, inputItem.payments!.mentor_payments!, inputItem.payments!.hr_manager_payments! )
                    let interview = new SourceType(inputItem.interview?.interview_count!, inputItem.interview?.interview_percent!, inputItem.interview?.interview_price! )
                    let comedToWelcome = new SourceType(inputItem.comed_to_welcome?.comed_to_welcome_count!, inputItem.comed_to_welcome?.comed_to_welcome_percent!, inputItem.comed_to_welcome?.comed_to_welcome_price! )
                    let welcome = new SourceType(inputItem.welcome?.welcome_count!, inputItem.welcome?.welcome_percent!, inputItem.welcome?.welcome_price! )
                    let education = new SourceType(inputItem.education?.education_count!, inputItem.education?.education_percent!, inputItem.education?.education_price! )
                    let exam = new SourceType(inputItem.exam?.exam_count!, inputItem.exam?.exam_percent!, inputItem.exam?.exam_price! )
                    let stat = new SourceType(inputItem.stat?.stat_count!, inputItem.stat?.stat_percent!, inputItem.stat?.stat_price! )
                    let trial = new SourceType(inputItem.trial?.trial_count!, inputItem.trial?.trial_percent!, inputItem.trial?.trial_price! )
                    let item = new ReportSourcesItem(inputItem.source_id!, inputItem.source_name!, inputItem.negotations_count!, inputItem.negotation_price!, payments, interview, welcome, comedToWelcome, education, exam, stat, trial )
                    items.push(item)
                })
                let sum = new Sums(new SumItem(0, response.data.sums?.traffic?.payment!, 0), new SumItem(0, response.data.sums?.recruiter?.payment!, 0), new SumItem(0, response.data.sums?.mentor?.payment!, 0), new SumItem(0, response.data.sums?.hr_manager?.payment!, 0),  new SumItem(response.data.sums?.negotations?.count!, response.data.sums?.negotations?.price!, response.data.sums?.negotations?.percent!), new SumItem(response.data.sums?.interview?.count!, response.data.sums?.interview?.price! , response.data.sums?.interview?.percent!), new SumItem(response.data.sums?.welcome?.count!, response.data.sums?.welcome?.price!, response.data.sums?.welcome?.percent!), new SumItem(response.data.sums?.comedToWelcome?.count!, response.data.sums?.comedToWelcome?.price!, response.data.sums?.comedToWelcome?.percent!), new SumItem(response.data.sums?.education?.count!, response.data.sums?.education?.price!, response.data.sums?.education?.percent!), new SumItem(response.data.sums?.exam?.count!, response.data.sums?.exam?.price!, response.data.sums?.exam?.percent!), new SumItem(response.data.sums?.trial?.count!, response.data.sums?.trial?.price!, response.data.sums?.trial?.percent!), new SumItem(response.data.sums?.stat?.count!, response.data.sums?.stat?.price!, response.data.sums?.stat?.percent!))
                console.log(response.data)
                return {items: items, sums: sum}
            }
        } catch (e) {
            console.log(e)
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async RecruitersReport(from: Date, to: Date): Promise<RecruitersReport | Error > {
        try {
            let response = await this.service.apiReportRecruitersGet(from.toISOString(), to.toISOString().replace('T20:59:59.000Z', 'T23:59:59.000Z'), { headers: { "Authorization": localStorage.getItem("token") } })
            
            if (response.status === 200) {
                let reportSources: RecruiterReportSource[] = [] 
                response.data.sources_list?.forEach((sourceItem) => {
                    let detalizationList: RecruiterReportDetalization[] = [] 
                    sourceItem.detalization?.forEach((item) =>{
                        detalizationList.push(new RecruiterReportDetalization(item.recruiter_id!, item.recruiter_name!, item.negotations_count!, item.spent_money!, item.payments!))
                    })
                    reportSources.push(new RecruiterReportSource(sourceItem.source_id!, sourceItem.source_name!, sourceItem.negotations_count!, sourceItem.spent_money!, sourceItem.payments!, detalizationList))
                })

                let sumsDetalization: RecruiterReportDetalization[] = []
                response.data.sums?.detalization?.forEach((item) => {
                    sumsDetalization.push(new RecruiterReportDetalization(item.recruiter_id!, item.recruiter_name!, item.negotations_count!, item.spent_money!, item.payments!))
                })
                let sums: RecruiterReportSums = new RecruiterReportSums(response.data.sums?.negotations_count!, response.data.sums?.spent_money!, response.data.sums?.payments!, sumsDetalization)
                let report = new RecruitersReport(reportSources, sums)
                return report
            }
        } catch (e) {
            console.log(e)
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async MentorsReport(from: Date, to: Date): Promise<MentorsReport | Error > {
        try {
            let response = await this.service.apiReportMentorsGet(from.toISOString(), to.toISOString().replace('T20:59:59.000Z', 'T23:59:59.000Z'), { headers: { "Authorization": localStorage.getItem("token") } })
            
            if (response.status === 200) {
                let reportSources: MentorReportItem[] = [] 
                response.data.items?.forEach((sourceItem) => {
                    let education = new SourceType(sourceItem.education?.count!, 0, sourceItem.education?.price!)
                    let exam = new SourceType(sourceItem.exam?.count!, sourceItem.exam?.percent!, sourceItem.exam?.price!)
                    let internshipStart = new SourceType(sourceItem.internshipStart?.count!, sourceItem.internshipStart?.percent!, sourceItem.internshipStart?.price!)
                    let internshipFinish = new SourceType(sourceItem.internshipFinish?.count!, sourceItem.internshipFinish?.percent!, sourceItem.internshipFinish?.price!)
                    let probationStart = new SourceType(sourceItem.probationStart?.count!, sourceItem.probationStart?.percent!, sourceItem.probationStart?.price!)
                    let probationFinish = new SourceType(sourceItem.probationFinish?.count!, sourceItem.probationFinish?.percent!, sourceItem.probationFinish?.price!)
                    reportSources.push(new MentorReportItem(sourceItem.mentor_id!, sourceItem.mentor_name!, sourceItem.department_id!, sourceItem.department_name!, sourceItem.payment!, education, exam, internshipStart, internshipFinish, probationStart, probationFinish))
                })

               let report = new MentorsReport(reportSources)
                return report
            }
        } catch (e) {
            console.log(e)
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async ReportList(): Promise<Report[] | Error> {
        try {
            let response = await this.service.apiReportListGet({headers: { "Authorization": localStorage.getItem("token")}})
            let reports: Report[] = []
            if (response.status === 200) {
                response.data.forEach((reportData) => {
                    let report: Report = new Report(reportData.name!, reportData.ident!)
                    reports.push(report)
                })
                return reports
            }
        } catch (e) {
            console.log(e)
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async RecruitersAutoReport(from: Date, to: Date): Promise<ReportAutoRecruitersReport | Error > {
        try {
            let response = await this.service.apiReportRecruitersAutoFromToGet(from.toISOString(), to.toISOString().replace('T20:59:59.000Z', 'T23:59:59.000Z'), { headers: { "Authorization": localStorage.getItem("token") } })
            
            if (response.status === 200) {
                return response.data
            }
        } catch (e) {
            console.log(e)
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async RecruitersCandidatesReport(from: Date, to: Date, type: string): Promise<ReportCandidatesReport | Error > {
        try {
            let response = await this.service.apiReportCandidatesFromToTypeGet(from.toISOString(), to.toISOString().replace('T20:59:59.000Z', 'T23:59:59.000Z'), type, { headers: { "Authorization": localStorage.getItem("token") } })
            
            if (response.status === 200) {
                return response.data
            }
        } catch (e) {
            console.log(e)
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }



}