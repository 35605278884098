export enum PaymentActionTypes {
    PAYMENT_REQUEST_SEND = 'payment_request_send',
    PAYMENT_ERROR = 'payment_error',
    PAYMENT_SUCCESS = 'payment_success',
    
    PAYMENT_CREATE_REQUEST = 'payment_create_request',
    PAYMENT_CREATE_SUCCESS = 'payment_create_success',
    PAYMENT_CREATE_ERROR = 'payment_create_error',

    PAYMENT_DELETE_REQUEST = 'payment_delete_request',
    PAYMENT_DELETE_SUCCESS = 'payment_delete_success',
    PAYMENT_DELETE_ERROR = 'payment_delete_error',

    PAYMENT_UPDATE_REQUEST = 'payment_update_request',
    PAYMENT_UPDATE_SUCCESS = 'payment_update_success',
    PAYMENT_UPDATE_ERROR = 'payment_update_error',
}
