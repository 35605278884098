
export class RecruitersReport {
    private sourcesList: RecruiterReportSource[];
    private sums: RecruiterReportSums;

    constructor (sourcesList: RecruiterReportSource[], sums: RecruiterReportSums) {
        this.sourcesList = sourcesList
        this.sums = sums
    }

    public SourcesList = (): RecruiterReportSource[] => {
        return this.sourcesList
    } 

    public Sums = (): RecruiterReportSums => {
        return this.sums
    } 

}


export class RecruiterReportSource {
    private sourceId: number;
    private sourceName: string;
    private negotationsCount: number;
    private spentMoney: number;
    private payments: number;
    private detalization: RecruiterReportDetalization[];

    constructor (sourceId: number, sourceName: string, negotationsCount: number, spentMoney: number, payments: number, detalization: RecruiterReportDetalization[]) {
        this.sourceId = sourceId
        this.sourceName = sourceName
        this.negotationsCount = negotationsCount
        this.spentMoney = spentMoney
        this.payments = payments
        this.detalization = detalization
    }

    public SourceId = (): number => {
        return this.sourceId
    }

    public SourceName = (): string => {
        return this.sourceName
    }

    public NegotationsCount = (): number => {
        return this.negotationsCount
    }

    public SpentMoney = (): number => {
        return this.spentMoney
    }

    public Payments = (): number => {
        return this.payments
    }

    public Detalization = (): RecruiterReportDetalization[] => {
        return this.detalization
    }
}

export class RecruiterReportDetalization {
    private recruiterId: number;
    private recruiterName: string;
    private negotationsCount: number;
    private spentMoney: number;
    private payments: number;

    constructor (recruiterId: number, recruiterName: string, negotationsCount: number, spentMoney: number, payments: number) {
        this.recruiterId =recruiterId;
        this.recruiterName =recruiterName;
        this.negotationsCount = negotationsCount;
        this.spentMoney = spentMoney;
        this.payments = payments;
    }

    public RecruiterId = (): number => {
        return this.recruiterId
    }

    public RecruiterName = (): string => {
        return this.recruiterName
    }

    public NegtationsCount = (): number => {
        return this.negotationsCount
    }

    public SpentMoney = (): number => {
        return this.spentMoney
    }

    public Payments = (): number => {
        return this.payments
    }
}

export class RecruiterReportSums {
    private negotationsCount: number;
    private spentMoney: number;
    private payments: number;
    private detalization: RecruiterReportDetalization[];

    constructor (negotationsCount: number, spentMoney: number, payments: number, detalization: RecruiterReportDetalization[]) {
        this.negotationsCount = negotationsCount
        this.spentMoney = spentMoney
        this.payments = payments
        this.detalization = detalization
    }

    public NegotationsCount = (): number => {
        return this.negotationsCount
    }

    public SpentMoney = (): number => {
        return this.spentMoney
    }

    public Payments = (): number => {
        return this.payments
    }

    public Detalization = (): RecruiterReportDetalization[] => {
        return this.detalization
    }
}