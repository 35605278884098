import { useContext, useState } from "react";
import { Payment } from "../../../../domain/payment/payment";
import { DotsVertical, Edit, Trash } from 'tabler-icons-react'
import { UseCasesContext } from "../../../../context/useCases";
import { Link, useParams } from "react-router-dom";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { ReportSourcesItem, Sums } from "../../../../domain/report/sources";

export const TableRowLast = (props: { sums: Sums }) => {
    const {id} = useParams()
    const [showMenu, setShowMenu] = useState(false)
    let useCases = useContext(UseCasesContext)

    return (<tr style={{fontWeight: "bold"}}>
    <td >Итого</td>
    <td>{Math.round(props.sums.Traffic().Price())}</td>
    <td>{Math.round(props.sums.Recruiter().Price())}</td>
    <td>{Math.round(props.sums.Mentor().Price())}</td>
    <td>{Math.round(props.sums.HrManager().Price())}</td>
    <td>{Math.round(props.sums.Negotations().Count())}</td>
    <td>{Math.round(props.sums.Negotations().Price())}</td>
    <td>{Math.round(props.sums.Interview().Count())}</td>
    <td>{Math.round(props.sums.Interview().Percent() * 100) + "%"}</td>
    <td>{Math.round(props.sums.Interview().Price())}</td>
    <td>{Math.round(props.sums.Welcome().Count())}</td>
    <td>{Math.round(props.sums.Welcome().Percent() * 100) + "%"}</td>
    <td>{Math.round(props.sums.Welcome().Price())}</td>
    <td>{Math.round(props.sums.ComedToWelcome().Count())}</td>
    <td>{Math.round(props.sums.ComedToWelcome().Percent() * 100) + "%"}</td>
    <td>{Math.round(props.sums.ComedToWelcome().Price())}</td>
    <td>{Math.round(props.sums.Education().Count())}</td>
    <td>{Math.round(props.sums.Education().Percent() * 100) + "%"}</td>
    <td>{Math.round(props.sums.Education().Price())}</td>
    <td>{Math.round(props.sums.Exam().Count())}</td>
    <td>{Math.round(props.sums.Exam().Percent() * 100) + "%"}</td>
    <td>{Math.round(props.sums.Exam().Price())}</td>
    <td>{Math.round(props.sums.Trial().Count())}</td>
    <td>{Math.round(props.sums.Trial().Percent() * 100) + "%"}</td>
    <td>{Math.round(props.sums.Trial().Price())}</td>
    <td>{Math.round(props.sums.Stat().Count())}</td>
    <td>{Math.round(props.sums.Stat().Percent() * 100) + "%"}</td>
    <td>{Math.round(props.sums.Stat().Price())}</td>
    
</tr>)
}
