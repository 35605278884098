import React, { useContext, useState } from "react";
import { Payment } from "../../../../domain/payment/payment";
import { DotsVertical, Edit, Trash } from 'tabler-icons-react'
import { UseCasesContext } from "../../../../context/useCases";
import { Link, useParams } from "react-router-dom";
import { RecruiterReportSource } from "../../../../domain/report/recruitersReport";
import { report } from "process";
import { MentorReportItem } from "../../../../domain/report/mentorsReport";

export const TableRow = (props: { report: MentorReportItem, showDep: boolean}) => {
    const {id} = useParams()
    const [showMenu, setShowMenu] = useState(false)
    let useCases = useContext(UseCasesContext)

    return (<tr>
        <td>{props.report.MentorName()}</td>
        {props.showDep?<td>{props.report.DepartmentName()}</td>:<></>}
        <td>{Math.round(props.report.Payment())}</td>
        <td>{props.report.Education().Count()}</td>
        <td>{Math.round(props.report.Education().Price())}</td>
        <td>{props.report.Exam().Count()}</td>
        <td>{Math.round(props.report.Exam().Price())}</td>
        <td>{Math.round(props.report.Exam().Percent()*100)+"%"}</td>
        <td>{props.report.InternshipStart().Count()}</td>
        <td>{Math.round(props.report.InternshipStart().Price())}</td>
        <td>{Math.round(props.report.InternshipStart().Percent()*100)+"%"}</td>
        <td>{props.report.InternshipFinish().Count()}</td>
        <td>{Math.round(props.report.InternshipFinish().Price())}</td>
        <td>{Math.round(props.report.InternshipFinish().Percent()*100)+"%"}</td>

        <td>{props.report.ProbationStart().Count()}</td>
        <td>{Math.round(props.report.ProbationStart().Price())}</td>
        <td>{Math.round(props.report.ProbationStart().Percent()*100)+"%"}</td>
        <td>{props.report.ProbationFinish().Count()}</td>
        <td>{Math.round(props.report.ProbationFinish().Price())}</td>
        <td>{Math.round(props.report.ProbationFinish().Percent()*100)+"%"}</td>

        
    </tr>)
}
