import React, { useContext, useEffect, useState } from "react"
import { UseCasesContext } from "../../../../context/useCases"
import { Link, useParams } from "react-router-dom"
import { Menu } from "../../../components/menu"
import { MentorReportItem, MentorsReport } from "../../../../domain/report/mentorsReport"
import { TableRow } from "./tableRow"
import { TableRowLast } from "./tableRowLast"

export const ReportMentors = () => {
    const [error, setError] = useState<string>()
    const [report, setReport] = useState<MentorsReport>()
    const [split, setSplit] = useState<boolean>(false)
    const [joinedItems, setJoinedItems] = useState<MentorReportItem[]>([])
    const [mentorsList, setMentorsList] = useState<{ name: string, id: number }[]>([])
    let { id } = useParams();

    const [monthsList, setMonthsList] = useState<{ name: string, date: string }[]>([])

    const [filter, setFilter] = useState<{
        from: Date,
        to: Date,
    }>({
        from: new Date(),
        to: new Date(),
    })

    const dateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let date = new Date(e.target.value)
        setFilter({
            ...filter,
            [e.target.name]: date
        })
    }

    const months = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"]

    const getDates = () => {
        let monthsList: { name: string, date: string }[] = []
        let date = new Date()
        date.setDate(date.getDate() - 180);
        while (date.getDate() !== 1) {
            date.setDate(date.getDate() - 1)
        }
        for (let i = 0; i < 7; i++) {
            monthsList.push({
                name: months[date.getMonth()] + " " + date.getFullYear(),
                date: formatDate(date)
            })
            date.setDate(date.getDate() + 30);
            if (date.getDate() < 15) {
                while (date.getDate() !== 1) {
                    date.setDate(date.getDate() - 1)
                }
            } else {
                while (date.getDate() !== 1) {
                    date.setDate(date.getDate() + 1)
                }
            }
        }
        setMonthsList([...monthsList])
    }

    const monthChange = (value: string) => {
        let lastDay = new Date(value)

        lastDay.setMonth(lastDay.getMonth() + 1)
        lastDay.setDate(lastDay.getDate() - 1)
        lastDay.setHours(23)
        lastDay.setMinutes(59)
        lastDay.setSeconds(59)
        setFilter({
            ...filter,
            from: new Date(value),
            to: lastDay,
        })
    }


    useEffect(() => {
        let date = new Date();
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        setFilter({
            ...filter,
            from: firstDay,
            to: lastDay,
        })

    }, [])

    let useCases = useContext(UseCasesContext)

    const readReport = async () => {
        let result = await useCases?.reportUseCase.ReadMentorsReport(filter.from, filter.to)
        if (result instanceof Error) {
            setError(result.message)
        } else {
            if (result) {
                setReport(result!)
            }
        }
    }

    useEffect(() => {
        if (filter.from) {
            readReport()
        }
        getDates()
    }, [filter])

    useEffect(() => {
        useCases?.sourceUseCase.ReadSources()
    }, [])

    const formatDate = (day: Date) => {
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return yyyy + "-" + mm + '-' + dd;
    }

    useEffect(()=>{
        let items: MentorReportItem[] = []
        let mapItems = new Map<string, MentorReportItem>()

        if (report?.ItemsList()) {
            for(let i = 0; i < report?.ItemsList()?.length; i++) {
                let item = report?.ItemsList()[i]
                let newItem: MentorReportItem
                if (mapItems.get(item.MentorName())) {
                    newItem = mapItems.get(item.MentorName())!.clone()
                    newItem = newItem.SumWithItem(item)
                } else {
                    newItem = item
                }
                mapItems.set(newItem.MentorName(), newItem)
            }

            for (let value of mapItems.values()) {
                items.push(value)
            }
        }
        setJoinedItems(items)
    }, [report])

    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-4">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Отчет /</span> Отчет по наставникам</h4>
                        </div>

                        <div className="card mb-4">
                            <div className="card-body">
                                <div className="row mb-3 align-items-center">
                                    <div className="col-12 col-sm-6 col-lg-4 justify-content-end">
                                        <label className="form-label">Месяц:</label>
                                        <select value={formatDate(filter.from)} onChange={(e) => monthChange(e.target.value)} name="from" className="form-select mb-3">
                                            {monthsList.map((monthItem) => {
                                                return <option value={monthItem.date}>{monthItem.name}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-sm-6 col-lg-4">
                                        <div className="form-check form-switch mb-2">
                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={split} onChange={()=>{setSplit(!split)}} />
                                            <label className="form-check-label" htmlFor="flexSwitchCheckChecked">С разбивкой по подразделениям</label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="card">
                            <div className="table-responsive text-nowrap">
                                <table className="table mt-1">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            {split?<th></th>:<></>}
                                            <th></th>
                                            <th colSpan={2} className="text-center">Назначено обучение</th>
                                            <th colSpan={3} className="text-center">Экзамен</th>
                                            <th colSpan={3} className="text-center">Приступил к стажировке</th>
                                            <th colSpan={3} className="text-center">Прошел стажировку</th>
                                            <th colSpan={3} className="text-center">Приступил к ИС</th>
                                            <th colSpan={3} className="text-center">Прошел ИС</th>
                                        </tr>
                                        <tr>
                                            <th>Наставник</th>
                                            {split?<th>Подразделение</th>:<></>}
                                            <th>ЗП</th>
                                            <th>Кол-во</th>
                                            <th>Цена</th>

                                            <th>Кол-во</th>
                                            <th>Цена</th>
                                            <th>%</th>

                                            <th>Кол-во</th>
                                            <th>Цена</th>
                                            <th>%</th>

                                            <th>Кол-во</th>
                                            <th>Цена</th>
                                            <th>%</th>

                                            <th>Кол-во</th>
                                            <th>Цена</th>
                                            <th>%</th>

                                            <th>Кол-во</th>
                                            <th>Цена</th>
                                            <th>%</th>

                                        </tr>

                                    </thead>
                                    <tbody className="table table-hover">
                                    {split?
                                        report?.ItemsList() && report?.ItemsList().map((item, index) => {
                                            return <TableRow report={item} showDep={split} />
                                        })
                                    :
                                        joinedItems && joinedItems.map((item, index) => {
                                            return <TableRow report={item} showDep={split} />
                                        })
                                    }
                                    {report?.ItemsList() ? <TableRowLast report={report?.ItemsList()!} showDep={split} /> : <></>}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

}