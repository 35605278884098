import axios from "axios";
import { User } from "../../../domain/user/user";
import { Configuration, UsersApi } from "../generated";
import { UsersInterface } from "../../../useCase/users/adapters/interface";

export class UsersRepository implements UsersInterface {
    private service: UsersApi;

    constructor() {
        this.service = new UsersApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async ReadUsers(): Promise<User[] | Error> {
        try {
            let response = await this.service.apiUserGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let users: User[] = []
                response.data.forEach(userData => {
                    let createdAt = new Date(userData.created_at!)
                    let modifiedAt = new Date(userData.modified_at!)

                    let user = new User(userData.id!, userData.name!, userData.login!, userData.pass!, userData.access_list!, createdAt, modifiedAt)
                    users.push(user)
                });
                return users
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async ReadUserById(id: UniqueId): Promise<User | Error> {
        try {
            let response = await this.service.apiUserIdGet(id, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let createdAt = new Date(response.data.created_at!)
                let modifiedAt = new Date(response.data.modified_at!)


                let user = new User(response.data.id!, response.data.name!, response.data.login!, response.data.pass!, response.data.access_list!, createdAt, modifiedAt)
                return user

            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async CreateUser(name: string, login: string, pass: string, accessList: string[]): Promise<User | Error> {
        try {
            let response = await this.service.apiUserPost({name: name, login: login, pass: pass, access_list: accessList}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let createdAt = new Date(response.data.created_at!)
                let modifiedAt = new Date(response.data.modified_at!)
                let user = new User(response.data.id!, response.data.name!, response.data.login!, response.data.pass!, response.data.access_list!, createdAt, modifiedAt)
                return user
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async UpdateUser(id: UniqueId, name: string, login: string, pass: string, accessList: string[]): Promise<User | Error> {
        try {
            let response = await this.service.apiUserIdPut(id, {name: name, login: login, pass: pass, access_list: accessList}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let createdAt = new Date(response.data.created_at!)
                let modifiedAt = new Date(response.data.modified_at!)
                let user = new User(response.data.id!, response.data.name!, response.data.login!, response.data.pass!, response.data.access_list!, createdAt, modifiedAt)
                return user
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async DeleteUser(id: UniqueId): Promise<string | Error> {
        try {
            let response = await this.service.apiUserIdDelete(id, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return "success"
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    
}