import { VacancyRepository } from "../../repository/api/vacancy/vacancy";
import { actionCreators } from "../../state";

export class VacancyUseCases {
    private api: VacancyRepository;
    private stateActions: typeof actionCreators;

    constructor(api: VacancyRepository, stateActions: typeof actionCreators) {
        this.api = api;
        this.stateActions = stateActions;
    }

    public ReadVacancysWithDouble = async (month: number) => {
        this.stateActions.SendVacancyListRequest()
        let response = await this.api.ReadVacancysOfMonthWithDoubles(month)
        if (response instanceof Error) {
            this.stateActions.VacancyListError(response.message)
            return
        } else {
            this.stateActions.VacancyListSuccess(response)
        }
    }

    public ReadVacancysWithoutDoubles = async (month: number) => {
        this.stateActions.SendVacancyListRequest()
        let response = await this.api.ReadVacancysOfMonthWithoutDoubles(month)
        if (response instanceof Error) {
            this.stateActions.VacancyListError(response.message)
            return
        } else {
            this.stateActions.VacancyListSuccess(response)
        }
    }

}