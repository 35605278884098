import { createContext } from 'react'
import { AuthorizationUseCases } from '../useCase/authorization/authorization';
import { PaymentUseCases } from '../useCase/payment/payment';
import { MentorUseCases } from '../useCase/mentor/mentor';
import { RecruiterUseCases } from '../useCase/recruiter/recruiter';
import { SourceUseCases } from '../useCase/source/source';
import { TypeOfSourceUseCases } from '../useCase/typeOfSource/typeOfSource';
import { VacancyUseCases } from '../useCase/vacancy/vacancy';
import { ReportUseCases } from '../useCase/report/report';
import { UserUseCases } from '../useCase/users/user';
import { DenyStageUseCases } from '../useCase/denyStage/denyStage';

interface UseCasesContextInterface {
    authUseCase: AuthorizationUseCases;
    paymentUseCase: PaymentUseCases;
    mentorUseCase: MentorUseCases;
    recruiterUseCase: RecruiterUseCases;
    sourceUseCase: SourceUseCases;
    typeOfSourceUseCase: TypeOfSourceUseCases;
    vacancyUseCase: VacancyUseCases;
    reportUseCase: ReportUseCases;
    userUseCase: UserUseCases;
    denyStageUseCase: DenyStageUseCases;
}

export const UseCasesContext = createContext<UseCasesContextInterface | null>(null)
