import axios from "axios";
import { Payment } from "../../../domain/payment/payment";
import { PaymentInterface } from "../../../useCase/payment/adapters/Interface";
import { Configuration, PaymentsApi } from "../generated";

export class PaymentsRepository implements PaymentInterface {
    private service: PaymentsApi;

    constructor() {
        this.service = new PaymentsApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async ReadPayments(from: Date, to:Date, mentorId: number, recruiterId: number, sourceId: number): Promise<Payment[] | Error> {
        try {
            let response = await this.service.apiPaymentGet(from.toISOString(), to.toISOString(), mentorId, recruiterId, sourceId, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let payments: Payment[] = []
                response.data.forEach(paymentData => {
                    let startDate = new Date(paymentData.startDate!)
                    let endDate = new Date(paymentData.endDate!)
                    let createdAt = new Date(paymentData.created_at!)
                    let modifiedAt = new Date(paymentData.modified_at!)

                    let payment = new Payment(paymentData.id!, startDate, endDate, paymentData.paymentType!, paymentData.entityId!, paymentData.mentorId!, paymentData.sourceId!, paymentData.typeOfSourceId!, paymentData.amount!, paymentData.vacancyId!, createdAt, modifiedAt)
                    payments.push(payment)
                });
                return payments
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async ReadPaymentById(id: UniqueId): Promise<Payment | Error> {
        try {
            let response = await this.service.apiPaymentIdGet(id, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let startDate = new Date(response.data.startDate!)
                let endDate = new Date(response.data.endDate!)
                let createdAt = new Date(response.data.created_at!)
                let modifiedAt = new Date(response.data.modified_at!)


                let payment = new Payment(response.data.id!, startDate!, endDate!, response.data.paymentType!, response.data.entityId!, response.data.mentorId!, response.data.sourceId!, response.data.typeOfSourceId!, response.data.amount!, response.data.vacancyId!, createdAt, modifiedAt)
                return payment

            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async CreatePayment(startDate: Date, endDate: Date, amount: number, entityId: number, paymentType: string, sourceId: number, typeOfSourceId: number, vacancyId: UniqueId): Promise<Payment | Error> {
        try {
            let response = await this.service.apiPaymentPost({startDate: startDate.toISOString(), endDate: endDate.toISOString(), amount: amount, entityId: entityId, paymentType: paymentType, sourceId: sourceId, typeOfSourceId: typeOfSourceId, vacancyId: vacancyId}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let startDate = new Date(response.data.startDate!)
                let endDate = new Date(response.data.endDate!)
                let createdAt = new Date(response.data.created_at!)
                let modifiedAt = new Date(response.data.modified_at!)
                let payment = new Payment(response.data.id!, startDate, endDate, response.data.paymentType!, response.data.entityId!, response.data.mentorId!, response.data.sourceId!, response.data.typeOfSourceId!, response.data.amount!, response.data.vacancyId!, createdAt, modifiedAt)
                return payment
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async UpdatePayment(id: UniqueId, startDate: Date, endDate: Date, amount: number, entityId: number, paymentType: string, sourceId: number, typeOfSourceId: number, vacancyId: UniqueId): Promise<Payment | Error> {
        try {
            let response = await this.service.apiPaymentIdPut(id, {startDate: startDate.toISOString(), endDate: endDate.toISOString(), amount: amount, entityId: entityId, paymentType: paymentType, sourceId: sourceId, typeOfSourceId: typeOfSourceId, vacancyId: vacancyId}, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let startDate = new Date(response.data.startDate!)
                let endDate = new Date(response.data.endDate!)
                let createdAt = new Date(response.data.created_at!)
                let modifiedAt = new Date(response.data.modified_at!)
                let tournament = new Payment(response.data.id!, startDate, endDate, response.data.paymentType!, response.data.entityId!, response.data.mentorId!, response.data.sourceId!, response.data.typeOfSourceId!, response.data.amount!, response.data.vacancyId!, createdAt, modifiedAt)
                return tournament
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    async DeletePayment(id: UniqueId): Promise<string | Error> {
        try {
            let response = await this.service.apiPaymentIdDelete(id, { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return "success"
            }
        } catch(e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }
            
            return Error("error authorization")
        }
        
        return Error("error authorization")
    }

    
}