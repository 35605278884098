import { useContext,  useEffect,  useState } from "react"
import { UseCasesContext } from "../../../../context/useCases"
import { useTypedSelector } from "../../../../hooks/useTypedSelector"
import { Link, useParams } from "react-router-dom"
import { TableRow } from "./tableRow"
import { Menu } from "../../../components/menu"
import { ReportSelectionSourceReport } from "../../../../repository/api/generated"
import { ReportSourcesItem, Sums } from "../../../../domain/report/sources"
import { TableRowLast } from "./tableRowLast"

export const AkcReport = () => {
    const [error, setError] = useState<string>()
    const [reports, setReports] = useState<ReportSourcesItem[]>([])
    const [sums, setSums] = useState<Sums>()
    let { id } = useParams();

    const [monthsList, setMonthsList] = useState<{ name: string, date: string }[]>([])

    const [filter, setFilter] = useState<{
        from: Date,
        to: Date,
    }>({
        from: new Date(),
        to: new Date(),
    })

    const dateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let date = new Date(e.target.value)
        setFilter({
            ...filter,
            [e.target.name]: date
        })
    }

    const months = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"]

    const getDates = () => {
        let monthsList: { name: string, date: string }[] = []
        let date = new Date()
        date.setDate(date.getDate() - 180);
        while (date.getDate() !== 1) {
            date.setDate(date.getDate() - 1)
        }
        for (let i = 0; i < 7; i++) {
            monthsList.push({
                name: months[date.getMonth()] + " " + date.getFullYear(),
                date: formatDate(date)
            })
            date.setDate(date.getDate() + 30);
            if (date.getDate() < 15) {
                while (date.getDate() !== 1) {
                    date.setDate(date.getDate() - 1)
                }
            } else {
                while (date.getDate() !== 1) {
                    date.setDate(date.getDate() + 1)
                }
            }
        }
        setMonthsList([...monthsList])
    }

    const monthChange = (value: string) => {
        let lastDay = new Date(value)

        lastDay.setMonth(lastDay.getMonth() + 1)
        lastDay.setDate(lastDay.getDate() - 1)
        lastDay.setHours(23)
        lastDay.setMinutes(59)
        lastDay.setSeconds(59)
        setFilter({
            ...filter,
            from: new Date(value),
            to: lastDay,
        })
    }


    useEffect(() => {
        let date = new Date();
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        setFilter({
            ...filter,
            from: firstDay,
            to: lastDay,
        })
    
    }, [])

    let useCases = useContext(UseCasesContext)

    const payments = useTypedSelector(({ payment }) => {
        return payment
    })

    const readReport = async () => {
        let result = await useCases?.reportUseCase.ReadAkcAllReport(filter.from, filter.to)
            if (result instanceof Error) {
                setError(result.message)
            } else {
                if (result) {
                    setSums(result!.sums)
                    setReports(result!.items)
                }
            }
    }

    useEffect(()=>{
        if (filter.from) {
            readReport()
        }
        getDates()
    },[filter])

    useEffect(()=>{
        useCases?.sourceUseCase.ReadSources()
    },[])

    const formatDate = (day: Date) => {
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return yyyy + "-" + mm + '-' +dd;
    }

    return (
        <div className="layout-page" >
            <Menu />
            <div className="content-wrapper" >
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row mx-1">
                        <div className="col-sm-12 col-md-4">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light">Отчет по источникам /</span> АКЦ Общий</h4>
                        </div>

                        <div className="card mb-4">
                            <div className="card-body">
                                <div className="row mb-3">
                                    <div className="col-12 col-sm-6 col-lg-4 justify-content-end">
                                        <label className="form-label">Месяц:</label>
                                        <select value={formatDate(filter.from)} onChange={(e) => monthChange(e.target.value)} name="from" className="form-select mb-3">
                                            {monthsList.map((monthItem) => {
                                                return <option value={monthItem.date}>{monthItem.name}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="table-responsive text-nowrap">
                                <table className="table mt-1">
                                    <thead>
                                        <tr>
                                            <th>Источник</th>
                                            <th>ЗП <br/> Настав</th>
                                            <th>Пришел <br/> на W</th>
                                            <th>%</th>
                                            <th>Ц</th>
                                            <th>Назнач <br/> обуч</th>
                                            <th>%</th>
                                            <th>Ц</th>
                                            <th>Экзамен</th>
                                            <th>%</th>
                                            <th>Ц</th>
                                            <th>Прошел С</th>
                                            <th>%</th>
                                            <th>Ц</th>
                                            <th>Прошел ИС</th>
                                            <th>%</th>
                                            <th>Ц</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table table-hover">
                                        {reports.map((report, index)=>{
                                            return <TableRow report={report} last={reports?.length - 1 == index?true:false}/>
                                        })}
                                        {sums?<TableRowLast sums={sums} />:<></>}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

}