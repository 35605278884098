import { useContext, useState } from "react";
import { Payment } from "../../../../domain/payment/payment";
import { DotsVertical, Edit, Trash } from 'tabler-icons-react'
import { UseCasesContext } from "../../../../context/useCases";
import { Link, useParams } from "react-router-dom";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";

export const TableRow = (props: { payment: Payment, last: boolean }) => {
    const {id} = useParams()
    const [showMenu, setShowMenu] = useState(false)
    let useCases = useContext(UseCasesContext)

    const formatDate = (day: Date) => {
        if (day.getFullYear() === 1) {
            return ""
        }
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        return dd + '.' + mm + '.' + yyyy.toString().substring(2);
    }
    const sources = useTypedSelector(({ source }) => {
        return source
    })

    const typeOfSources = useTypedSelector(({ typeOfSource }) => {
        return typeOfSource
    })

    const mentors = useTypedSelector(({ mentor }) => {
        return mentor
    })

    const recruiters = useTypedSelector(({ recruiter }) => {
        return recruiter
    })

    const vacancies = useTypedSelector(({ vacancy }) => {
        return vacancy
    })

    const pickSource = (id:number) => {
        if (!id) {
            return
        }
        if (!sources?.sources) {
            return
        }
        for (let i = 0; i < sources?.sources!.length; i++) { 
            if (sources.sources[i].Id() === id) {
                return sources.sources[i].Name()
            }
        }
        return id
    }

    const pickSourceType = (id:number) => {
        if (!id) {
            return
        }
        if (!typeOfSources?.typeOfSources) {
            return
        }
        for (let i = 0; i < typeOfSources?.typeOfSources!.length; i++) { 
            if (typeOfSources.typeOfSources[i].Id() === id) {
                return typeOfSources.typeOfSources[i].Name()
            }
        }
        return id
    }

    const pickPaymentType = (type:string) => {
        if (type === "mentor") {
            return "Наставник"
        } else if (type === "recruiter") {
            return "Рекрутер"
        } else if (type === "hr_manager") {
            return "HR-менеджер"
        } else if (type === "head_of_study") {
            return "Руководитель обучения"
        }

        return "Траффик"
    }

    const pickRecruiter = (id:number) => {
        if (id === 0) {
            return
        }
        if (!recruiters?.recruiters) {
            return id
        }
        for (let i = 0; i < recruiters?.recruiters!.length; i++) { 
            if (recruiters.recruiters[i].Id() === id) {
                return recruiters.recruiters[i].Name()
            }
        }
        return id
    }

    const pickMentor = (id:string) => {
        if (id === "") {
            return
        }
        if (!mentors?.mentors) {
            return id
        }
        for (let i = 0; i < mentors?.mentors!.length; i++) { 
            if (mentors.mentors[i].Id() === id) {
                return mentors.mentors[i].Name()
            }
        }
        return id
    }

    const pickMentorInt = (id:number) => {
        if (id === 0) {
            return
        }
        if (!mentors?.mentors) {
            return id
        }
        for (let i = 0; i < mentors?.mentors!.length; i++) { 
            if (mentors.mentors[i].MentorId() === id || mentors.mentors[i].MentorStudyId() === id || mentors.mentors[i].MentorInternshipId() === id || mentors.mentors[i].MentorProbationId() === id) {
                return mentors.mentors[i].Name()
            }
        }
        return id
    }

    const pickVacancy = (id:string) => {
        if (id === "00000000-0000-0000-0000-000000000000") {
            return
        }
        if (!vacancies?.vacancies) {
            return id
        }
        for (let i = 0; i < vacancies?.vacancies!.length; i++) { 
            if (vacancies.vacancies[i].Id() === id) {
                return vacancies.vacancies[i].Name()
            }
        }
        return id
    }

    const pickVacancyCity = (id:string) => {
        if (id === "00000000-0000-0000-0000-000000000000") {
            return
        }
        if (!vacancies?.vacancies) {
            return id
        }
        for (let i = 0; i < vacancies?.vacancies!.length; i++) { 
            if (vacancies.vacancies[i].Id() === id) {
                return vacancies.vacancies[i].City()
            }
        }
        return id
    }

    const pickVacancyType = (id:string) => {
        if (id === "00000000-0000-0000-0000-000000000000") {
            return
        }
        if (!vacancies?.vacancies) {
            return id
        }
        for (let i = 0; i < vacancies?.vacancies!.length; i++) { 
            if (vacancies.vacancies[i].Id() === id) {
                return vacancies.vacancies[i].Type()
            }
        }
        return id
    }

    const pickVacancyDate = (id:string) => {
        if (id === "00000000-0000-0000-0000-000000000000") {
            return
        }
        if (!vacancies?.vacancies) {
            return id
        }
        for (let i = 0; i < vacancies?.vacancies!.length; i++) { 
            if (vacancies.vacancies[i].Id() === id) {
                return formatDate(vacancies.vacancies[i].Date())
            }
        }
        return id
    }

    const withDel = () => {
        return window.location.href.toString().indexOf('with_delete=y') !== -1
    }

    return (<tr>
        <td>{formatDate(props.payment.StartDate())+ " - " + formatDate(props.payment.EndDate())}</td>
        <td>{pickSourceType(props.payment.TypeOfSourceId())}</td>
        <td>{pickSource(props.payment.SourceId())}</td>
        <td>{pickPaymentType(props.payment.PaymentType())}</td>
        <td>{props.payment.PaymentType()==="recruiter"?pickRecruiter(props.payment.EntityId()):props.payment.MentorId()== "00000000-0000-0000-0000-000000000000" ? pickMentorInt(props.payment.EntityId()):pickMentor(props.payment.MentorId())}</td>
        <td>{pickVacancy(props.payment.VacancyId())}</td>
        <td>{pickVacancyCity(props.payment.VacancyId())}</td>
        <td>{pickVacancyType(props.payment.VacancyId())}</td>
        <td>{pickVacancyDate(props.payment.VacancyId())}</td>
        <td>{props.payment.Amount()}</td>
        <td>
            <div className="dropdown">
                <button type="button" onClick={()=>{setShowMenu(!showMenu)}} className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                    <DotsVertical />
                </button>
                <div className={showMenu?"dropdown-menu show":"dropdown-menu "} style={showMenu ? props.last ? { position: "absolute", inset: "auto auto 0px -20%", margin: "0px", transform: "translate3d(0px, -20.5px, 0px)" }: {position: "absolute", marginLeft: "-40%",}: {display:"none"}}>
                    <Link className="dropdown-item d-flex" to={"/report/payment/edit/"+props.payment.Id()}><Edit className="me-1" size={20} /> Редактировать</Link>
                    {props.payment.PaymentType() == "recruiter" || withDel() ? <a className="dropdown-item d-flex" href="#" onClick={(e)=>{e.preventDefault(); useCases?.paymentUseCase.DeletePayment(props.payment.Id()); setShowMenu(false)}}><Trash className="me-1" size={20} /> Удалить</a>: <></>}
                </div>
            </div>
        </td>
    </tr>)
}
