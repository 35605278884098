export class Vacancy {
    private id: UniqueId;
    private name: string;
    private city: string;
    private type: string;
    private date: Date;
    private createdAt: Date;
    private modifiedAt: Date;

    constructor(id: UniqueId, name: string, city: string, type: string, date: Date, createdAt: Date, modifiedAt: Date) {
        this.id = id;
        this.name = name;
        this.city = city;
        this.type = type;
        this.date = date;
        this.createdAt = createdAt;
        this.modifiedAt = modifiedAt;
    }

    public Id = (): UniqueId => {
        return this.id
    }

    public Name = (): string => {
        return this.name
    }

    public City = (): string => {
        return this.city
    }

    public Type = (): string => {
        return this.type
    }

    public Date = (): Date => {
        return this.date
    }

    public CreatedAt = (): Date => {
        return this.createdAt
    }

    public ModifiedAt = (): Date => {
        return this.modifiedAt
    }

}