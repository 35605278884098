import { Vacancy } from "../../domain/vacancy/vacancy";
import { VacancyActionTypes } from "../action-types";
import { VacancyActions } from "../actions";
import produce from 'immer';


interface VacancyState {
    loading: boolean | null;
    vacancies: Vacancy[] | null,
    error: string | null,
    entityLoading: boolean,
    entityError: string | null,
}

const initialState: VacancyState = {
    loading: null,
    vacancies: null,
    error: null,
    entityLoading: false,
    entityError: null,
}



const reducer = produce((state: VacancyState = initialState, action: VacancyActions) => {
    switch (action.type) {
        case VacancyActionTypes.VACANCY_REQUEST_SEND:
            state.loading = true;
            return state;
        case VacancyActionTypes.VACANCY_SUCCESS:
            state.loading = false;
            state.vacancies = action.payload;
            return state;
        case VacancyActionTypes.VACANCY_ERROR:
            state.loading = false;
            state.error = action.payload;
            return state;
        default:
            return state;
    }
})

export default reducer;
