import axios from "axios";
import { Configuration, DenyStageApi} from "../generated";

export class DenyStageRepository {
    private service: DenyStageApi;

    constructor() {
        this.service = new DenyStageApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async ReadDenyStageMap(): Promise<Map<string, string[]> | Error> {
        try {
            let response = await this.service.apiDenyStageGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                let denyStageMap: Map<string, string[]> = new Map();
                Object.keys(response.data).map(stage => {
                    denyStageMap.set(stage, response.data[stage])
                });
                return denyStageMap
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async ReadNotUsedDenyStageValues(): Promise<string[] | Error> {
        try {
            let response = await this.service.apiDenyStageNotUsedGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return response.data
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async CreateDenyStageMap(stage: string, value: string): Promise<string | Error> {
        try {
            let response = await this.service.apiDenyStagePost({stage, value},{ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200) {
                return "ok"
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

    async DeleteDenyStageMap(name: string): Promise<string | Error> {
        try {
            let response = await this.service.apiDenyStageNameDelete(name,{ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 201) {
                return "ok"
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error("error authorization")
        }

        return Error("error authorization")
    }

}