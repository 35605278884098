export class Payment {
    private id: UniqueId;
    private startDate: Date;
    private endDate: Date;
    private paymentType: string;
    private amount: number;
    private entityId: number;
    private mentorId: UniqueId;
    private sourceId: number;
    private typeOfSourceId: number;
    private vacancyId: string;
    private createdAt: Date;
    private modifiedAt: Date;

    constructor(id: UniqueId, startDate: Date, endDate: Date, paymentType: string, entityId: number, mentorId: UniqueId, sourceId: number, typeOfSourceId: number, amount: number, vacancyId: string, createdAt: Date, modifiedAt: Date) {
        this.id = id;
        this.startDate = startDate;
        this.endDate = endDate;
        this.paymentType = paymentType;
        this.amount = amount;
        this.entityId = entityId;
        this.mentorId = mentorId;
        this.sourceId = sourceId;
        this.typeOfSourceId = typeOfSourceId;
        this.createdAt = createdAt;
        this.vacancyId = vacancyId;
        this.modifiedAt = modifiedAt;
    }

    public Id = (): UniqueId => {
        return this.id
    }

    public StartDate = (): Date => {
        return this.startDate
    }


    public EndDate = (): Date => {
        return this.endDate
    }

    public PaymentType = (): string => {
        return this.paymentType
    }

    public EntityId = (): number => {
        return this.entityId
    }

    public MentorId = (): UniqueId => {
        return this.mentorId
    }

    public Amount = (): number => {
        return this.amount
    }

    public SourceId = (): number => {
        return this.sourceId
    }

    public TypeOfSourceId = (): number => {
        return this.typeOfSourceId
    }

    public VacancyId = (): string => {
        return this.vacancyId
    }

    public CreatedAt = (): Date => {
        return this.createdAt
    }

    public ModifiedAt = (): Date => {
        return this.modifiedAt
    }

}